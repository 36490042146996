import axios from 'axios'
const state = {
  monthlyFeeds: {},
  scheduledHistory: {},
  clientsData: {},
  calendarData: {},
  calendarClientData: {},
  CancelFeed:{}
};
const getters = {
  monthlyFeedsList: (state) => state.monthlyFeeds,
  historyData: (state) => state.scheduledHistory,
  editMonthlyFeedData: (state) => state.editMonthly_feed,
  clientsList: (state) => state.clientsData,
  CalendarFeed: (state) => state.calendarData,
  CalendarClientFeed: (state) => state.calendarClientData,
  CancelScheduledFeedRes:(state) => state.CancelFeed,  
};
const actions = {
     async fetchMonthlyFeeds({ commit }, data) {
       await axios
      .get(
        `${process.env.VUE_APP_BASEURL}/getMonthlyFeeds?page=${data.page}&per_page=${data.perPage}&search=${data?.search}&feed_type=${data?.feed_type}&selected_month=${data?.selected_month}`,
        {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {       
        commit("setMonthlyFeeds", response.data);
      })
      .catch((err) => {
       if (err.message == "Request failed with status code 401") {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }
        if (err.message == "Request failed with status code 422") {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }

      });
  },
  async fetchMonthlyFeedHistory({ commit }) {    
    await axios.get(`${process.env.VUE_APP_BASEURL}/getMonthlyFeedHistory`, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then((response) => {
      commit("setScheduledHistory", response.data)
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });

  },
  async editMonthlyFeed({ commit }, data) {    
    await axios.get(`${process.env.VUE_APP_BASEURL}/editMonthlyFeed?feed_id=${data.feed_id}&scheduled_date=${data.date}`, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then((response) => {   
      commit("setEditMonthlyFeed", response.data)
      return response.data;
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });
  },  
  async cancelScheduledFeeds({ commit }, data) {
      await  axios.post(process.env.VUE_APP_BASEURL + `/cancel-scheduled-monthly-feeds`, data, {
        headers: {
          "Content-type": "application/json",
          token: `${localStorage.getItem("token")}`,
        },
      }).then((response) => {       
          commit("setCancelFeed", response.data)     
      }).catch(() => {
        if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
      });
  },
  async fetchClients({ commit }, data) {    
    await axios.get(`${process.env.VUE_APP_BASEURL}/get-clients?search=${data.search}&ids=${data.ids}`, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then((response) => {   
      commit("setClients", response.data)
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });
  },  
  async fetchCalendar({ commit }) {    
    await axios.get(`${process.env.VUE_APP_BASEURL}/getMonthlyFeedCalendar`, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then((response) => {   
      commit("setCalendar", response.data)
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });
  },
  async fetchClientCalendar({ commit }, data) {
    await axios.get(`${process.env.VUE_APP_BASEURL}/getClientMonthlyFeedCalendar?scheduledFeed=${data.ScheduledFeed}&startDate=${data.startDate}&endDate=${data.endDate}`, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then((response) => {   
      commit("setClientCalendar", response.data)
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });
},
  
};
const mutations = {
  setMonthlyFeeds: (state, feeds) => {
    // console.log(feeds,feeds.monthly_feeds.data);
    if (feeds) {
      (state.monthlyFeeds = feeds);         
    } else {
      (state.monthlyFeeds = {});
    }   
  }, 
  setScheduledHistory: (state, history) => { 
    if (history) {     
      (state.scheduledHistory = history);         
    } else {
      (state.scheduledHistory = {});
    }   
  },
  setEditMonthlyFeed: (state, edit) => {   
    if (edit.edit_scheduled_feeds) {     
      (state.editMonthly_feed = edit.edit_scheduled_feeds);         
    } else {
      (state.editMonthly_feed = {});
    }   
  },
  setClients: (state, clients) => {   
    if (clients) {     
      (state.clientsData = clients);         
    } else {
      (state.clientsData = {});
    }
  },
  setCalendar: (state, calendar) => { 
    if (calendar.calendar_feed) {     
      (state.calendarData = calendar.calendar_feed);         
    } else {
      (state.calendarData = {});
    }
  },
  setClientCalendar: (state, calendar) => {   
    if (calendar.client_calendar_feed) {     
      (state.calendarClientData = calendar.client_calendar_feed);         
    } else {
      (state.calendarClientData = {});
    }
  },
  setCancelFeed: (state, Cancel) => {   
    if (Cancel) {     
      (state.CancelFeed = Cancel);         
    } else {
      (state.CancelFeed = {});
    }
  },
  filterFeed: (state, data) =>
    // console.log('state',state,data, state.cloneFeeds.filter((u=>u.type==data))),
    (state.feeds = state.cloneFeeds.filter((u) => u.type == data)),
};
export default {
  state,
  getters,
  actions,
  mutations
}
<template>
    <div>
        <div class="page-content page-container" id="page-content">
            <div class="padding">
                <div class="row">
                    <div class="col-xl-12 col-md-12">
                        <div class="d-flex justify-content-between">
                            <h4>Update Widget</h4>
                            <div>
                                <router-link to="/widgets" class="btn btn-sm btn-primary">
                                    <i class="bi bi-caret-left-fill"></i>
                                    Back
                                </router-link>
                                <button 
                                    type="button"
                                    class="ms-2 btn btn-sm btn-primary"
                                    v-tooltip="'Copy Widget code'"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewCode"
                                >
                                    Widget Code
                                </button>
                                <button 
                                    type="button"
                                    v-tooltip="'Open Preview'"
                                    @click="handelPreviewWidget"
                                    class="ms-2 btn btn-sm btn-primary d-none"
                                    v-if="formFields.config.tags.length > 0"
                                >
                                    Preview
                                </button>
                            </div>
                        </div>

                        <div class="card user-card-full">
                            <div class="row gx-0">
                                <div class="col-md-12">
                                    <div class="row m-0 mb-0 mt-3 pb-0">
                                        <div class="col-md-6">
                                            <label for="title" class="form-label">
                                                Title (The Title will not display in widget) 
                                            </label>
                                            <input
                                                id="title"
                                                type="text"
                                                name="title"
                                                class="form-control"
                                                :class="[$v.formFields.title.$error ? 'is-invalid': '']"
                                                v-model="formFields.title"
                                                placeholder="Title of Widget"
                                            />
                                        </div>
    
                                        <div class="col-md-6">
                                            <label for="templates" class="form-label">
                                                Layouts
                                            </label>
                                            <select 
                                                id="templates"
                                                class="form-select"
                                                v-model="formFields.config.template"
                                            >
                                                <option value="list">
                                                    List
                                                </option>
                                                <option value="carousel">
                                                    Carousel
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row m-0" label="Sorting">
                                        <div class="col-md-6 d-none">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="sort_field" class="form-label">
                                                            Sort Field <i class="bi bi-info-circle" v-tooltip="` The 'Sort Field' option allows you to organize the reviews based on specific values, giving you the ability to sort them according to your preferences. `"></i>
                                                        </label>
                                                        <select id="sort_field" class="form-select" v-model="formFields.config.sortField">
                                                            <option value="reviews.created_at">
                                                                Date
                                                            </option>
                                                            <option value="reviews.rating">
                                                                Rating
                                                            </option>
                                                            <option value="reviews.author">
                                                                Author Name
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="sort_type" class="form-label">
                                                            Sort Type <i class="bi bi-info-circle" v-tooltip="` The 'Sort Type' option allows you to arrange the reviews in a specific order, either Ascending or Descending, based on the value you selected in the 'Sort Field' option. `"></i>
                                                        </label>
                                                        <select id="sort_type" class="form-select" v-model="formFields.config.sortType">
                                                            <option value="ASC">
                                                                ASC
                                                            </option>
                                                            <option value="DESC">
                                                                DESC
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mt-3">
                                                <div class="col-md-6">
                                                    <div class="mb-3 pb-0">
                                                        <label for="card_text_color" class="form-label">
                                                            Card Text Color <i class="bi bi-info-circle" v-tooltip="`Select the color for the review text.`"></i>
                                                        </label>
                                                        <input
                                                            id="card_text_color"
                                                            type="color"
                                                            class="form-control"
                                                            v-model.lazy="formFields.config.card_text_color"
                                                        />                                              
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3 pb-0">
                                                        <label for="card_background_color" class="form-label">
                                                            Card Background color <i class="bi bi-info-circle" v-tooltip="`Choose a background color for the review card.`"></i>
                                                        </label>
                                                        <input
                                                            id="card_background_color"
                                                            type="color"
                                                            class="form-control"
                                                            v-model.lazy="formFields.config.card_background_color"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mt-2">
                                                <template v-if="formFields.config.template == 'slider' || formFields.config.template == 'carousel' ">
                                                    <div class="col-md-6 col-lg-6 col-sm-6 col-6">
                                                        <div class="mb-3 pb-0 px-0">
                                                            <label for="loop" class="form-label">
                                                                Loop <i class="bi bi-info-circle" v-tooltip="`The 'Loop' option ensures that if the user reaches the end of the reviews while sliding through them one by one, the review carousel/slider will automatically restart from the first review, rather than stopping, if it is enabled.`"></i>
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="loop"
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.loopEnable"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                    <div class="col-md-6 col-lg-6 col-sm-6 col-6">
                                                        <div class="mb-3 pb-0 px-0">
                                                            <label for="pagination_dots" class="form-label">
                                                                Pagination Dots <i class="bi bi-info-circle" v-tooltip="`If the option is enabled, the user will be able to see navigation dots at the bottom of the slider/carousel, allowing them to easily track their position and navigate through the reviews.`"></i>
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="pagination_dots"
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.paginationEnable"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                    <div class="col-md-3 col-lg-3 d-none">
                                                        <div class="mb-3 p-3 pb-0 px-0">
                                                            <label for="auto_play" class="form-label">
                                                                Autoplay <i class="bi bi-info-circle" v-tooltip="`If the 'Autoplay' option is enabled, the slider/carousel will automatically scroll through the reviews without any user interaction.`"></i>
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="auto_play"
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.autoPlay"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
            
                                                    <div class="col-md-3 col-lg-3" v-if="formFields.config.autoPlay">
                                                        <div class="mb-3 p-3 pb-0 px-0">
                                                            <label for="auto_play_delay" class="form-label">
                                                                Autoplay Delay <i class="bi bi-info-circle" v-tooltip="`The 'Autoplay Delay' is the time interval between each slide when the slider automatically transitions from one review to the next.`"></i>
                                                            </label>
                                                            <select id="auto_play_delay" class="form-select" v-model="formFields.config.autoPlayDelay">
                                                                <option value="1000">
                                                                    1 Sec.
                                                                </option>
                                                                <option value="2000">
                                                                    2 Sec.
                                                                </option>
                                                                <option value="3000">
                                                                    3 Sec.
                                                                </option>
                                                                <option value="4000">
                                                                    4 Sec.
                                                                </option>
                                                                <option value="5000">
                                                                    5 Sec.
                                                                </option>
                                                                <option value="10000">
                                                                    10 Sec.
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template label="Per page option" v-if="formFields.config.template && ['list','grid','masonry'].includes(formFields.config.template)">
                                                    <div class="col-md-6 col-lg-6 col-sm-6 col-6">
                                                        <div class="mb-3 pb-0 px-0">
                                                            <label for="per_page" class="form-label">
                                                                View in one load <i class="bi bi-info-circle" v-tooltip="`The 'View in One Load' option determines the number of reviews that will be displayed each time the user clicks the 'Load More' button. `"></i>
                                                            </label>
                                                            <select id="per_page" v-model="formFields.config.perPage" class="form-select" >
                                                                <option value="6">6</option>
                                                                <option value="8">8</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-6 col-lg-6 col-sm-6 col-6">
                                                        <div class="mb-3 pb-0 px-0">
                                                            <label for="load_more" class="form-label">
                                                                Load More Option <i class="bi bi-info-circle" v-tooltip="`The 'Load More' button appears at the bottom of the widget when the number of reviews exceeds the limit 'View in one load', allowing users to load additional reviews. if the option is enabled. `"></i> 
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="load_more"
                                                                    type="checkbox"
                                                                    class="form-check-input"
                                                                    v-model="formFields.config.loadMore"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        
                                    </div>
                                    <div class="row m-0" label="colors">
                                        <div class="col-md-3 d-none">
                                            <div class="mb-3 p-3 pb-0">
                                                <label for="colors" class="form-label">
                                                    Colors Collection <i class="bi bi-info-circle" v-tooltip="`The colors are applied only to the card text, card background, and card hover effects. Please check the preview by clicking the 'Preview' button.`"></i>
                                                </label>
                                                <select id="colors" class="form-select" v-model="formFields.config.colorsCollection">
                                                    <option v-for="color in colorsCollections" :value="color.name">
                                                        {{color.label}}
                                                    </option>
                                                    <option value="custom">
                                                        Custom
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="row mb-3 p-3 pb-0 d-none">
                                                <label for="colors_collection" class="form-label mb-3">
                                                    Themes <i class="bi bi-info-circle" v-tooltip="`The colors are applied only to the card text, card background, and card hover effects.`"></i>
                                                </label>
                                                <div 
                                                    class="col-sm-12 col-md-4 col-lg-2 mb-1 mt-2 template-div" 
                                                    v-for="(template, index) in colorsCollections">
                                                    <small class="selected-text" v-if="template.name == formFields.config.colorsCollection"><strong>Selected</strong></small>
                                                    <div
                                                        :class="template.name == formFields.config.colorsCollection ? 'active' : ''" 
                                                        class="template-card">
                                                        <img 
                                                            @click="() => { formFields.config.colorsCollection = template.name }"
                                                            :src="template.theme_image" class="w-100">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-2 mb-1 mt-2 template-div" >
                                                    <small class="selected-text" v-if="formFields.config.colorsCollection == 'custom'"><strong>Selected</strong></small>
                                                    <div
                                                        :class=" formFields.config.colorsCollection == 'custom' ? 'active' : ''" 
                                                        class="template-card">
                                                        <img @click="() => { formFields.config.colorsCollection = 'custom' }" :src="customThemeImage" class="w-100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 d-none">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="card_background_hover_color" class="form-label">
                                                            Card Background Hover color <i class="bi bi-info-circle" v-tooltip="`Select the hover color for the review card, which changes when the user hovers over it with their mouse.`"></i>
                                                        </label>
                                                        <input
                                                            id="card_background_hover_color"
                                                            type="color"
                                                            class="form-control"
                                                            v-model="formFields.config.card_background_hover_color"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-4 d-none">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="tabs" class="form-label">
                                                            Tabs <i class="bi bi-info-circle" v-tooltip="`Toggle to show or hide the social account tabs at the top of the widget for filtering reviews by platform.`"></i> 
                                                        </label>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                id="tabs"
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                v-model="formFields.config.tabsEnable"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 d-none">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="overall_rating" class="form-label">
                                                            Overall Rating <i class="bi bi-info-circle" v-tooltip="`Toggle to show or hide the overall rating below the tabs in the widget.`"></i>
                                                        </label>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                id="overall_rating"
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                v-model="formFields.config.ratingInfo"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 d-none">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="custom_css_enable" class="form-label">
                                                            Custom Design Enable (CSS) <i class="bi bi-info-circle" v-tooltip="`Use the 'Custom CSS' option to apply your own design changes to the widget as desired.`"></i>
                                                        </label>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                id="custom_css_enable"
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                v-model="formFields.config.customCssEnable"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12" v-if="formFields.config.customCssEnable">
                                                    <div class="mb-3 p-3 pb-0">
                                                        <label for="custom_css" class="form-label">Custom CSS</label>
                                                        <vue-monaco-editor
                                                            v-model="formFields.config.customCss"
                                                            @change="onChangeEditor"
                                                            theme="vs-dark"
                                                            id="custom_css"
                                                            language="css"
                                                            style="height: 250px;"
                                                            :options="{
                                                                automaticLayout: true,
                                                                formatOnType: true,
                                                                formatOnPaste: true
                                                            }"
                                                        />
                                                        <small>Please note, we cannot guarantee that the classes will remain unchanged in future updates.</small>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-1 p-3 pb-0 px-0">
                                                        <label for="" class="form-label">
                                                            Central Tags (Select Tags To Filter the reviews)
                                                        </label>
                                                        <br />
                                                        <template v-for="tag in tags">
                                                            <div class="form-check form-check-inline mt-1 default_tag_input"
                                                                v-if="
                                                                    tag.id &&
                                                                    !tag.user_id
                                                                "
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    class="form-check-input widget-tag-checkbox"
                                                                    :id="`${tag.title}_${tag.id}`"
                                                                    :value="tag.id"
                                                                    :checked="formFields.config.tags.length > 0 && formFields.config.tags.includes(`${tag.id}`) ? true : false"
                                                                    @click="handelTags"
                                                                    :disabled="isReviewLoading"
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    :for="`${tag.title}_${tag.id}`"
                                                                    >
                                                                    {{ tag.title }}
                                                                </label>
                                                            </div>
                                                        </template>
                                                        <p class="default_tag_not_found d-none">
                                                            <small class="text-danger">Tags not found. This could be because there are no reviews available or the reviews have not been assigned any tags.</small>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3 p-3 pb-0 px-0">
                                                        <label for="" class="form-label">
                                                            Custom Tags (Select Tags To Filter the reviews)
                                                        </label>
                                                        <br />
                                                        <template v-for="tag in tags">
                                                            <div class="form-check form-check-inline mt-1 custom_tag_input" 
                                                                v-if=" tag.id && tag.user_id">
                                                                <input
                                                                    type="checkbox"
                                                                    class="form-check-input widget-tag-checkbox"
                                                                    :id="`${tag.title}_${tag.id}`"
                                                                    :value="tag.id"
                                                                    :checked="formFields.config.tags.length > 0 && formFields.config.tags.includes(`${tag.id}`) ? true : false"
                                                                    @click="handelTags"
                                                                    :disabled="isReviewLoading"
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    :for="`${tag.title}_${tag.id}`"
                                                                    >
                                                                    {{tag.title }}
                                                                </label>
                                                            </div>
                                                        </template>
                                                        <p class="custom_tag_not_found d-none">
                                                            <small class="text-danger">Custom tags not found. This could be because there are no reviews available or the reviews have not been assigned any tags.</small>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <div class="col-md-12 mt-3">
                                                        <button @click="handelUpdateWidget" :disabled="formLoading" class="mt-2 btn btn-sm btn-primary">
                                                            Update Widget
                                                            <Loading v-if="formLoading" />
                                                        </button>
                                                    </div>
                                                    <div class="col-md-6 d-none">
                                                        <label for="fonts" class="form-label">
                                                            Font Family
                                                        </label>
                                                        <v-select
                                                            label="label"
                                                            v-model="formFields.config.fontFamily"
                                                            id="fonts"
                                                            placeholder="select Font"         
                                                            :options="fonts"
                                                        ></v-select>
                                                        <p class="fs-6">Note: This is for review purposes only. On the live site, the fonts will be displayed based on the type of response received.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 mb-3 p-3 pb-0 w-100">
                                        <div class="d-flex justify-content-between flex-wrap">
                                            <label for="" class="form-label">
                                                Preview <i class="bi bi-eye"></i>
                                            </label>
                                            <div class="d-flex gap-md-4 flex-wrap">
                                                <label for="" class="form-label">
                                                    <strong>Font Used: </strong>{{ formFields.config.fontFamily.value }}
                                                </label>
                                                <label for="" class="form-label">
                                                    <strong>Note: </strong>On the live site, the font will depend on the response received.
                                                </label>
                                            </div>
                                        </div>
                                        <div style="height: 800px;" id="iframe-preview-div" class="border-secondary border">
                                            <div class="p-2" style="height: 100%;">
                                                <div class="d-flex justify-content-center align-items-center iframe-loader h-100 w-100" v-if="previewLoader || iframeErrorMsg">
                                                    <span class="badge bg-danger fs-6" v-if="iframeErrorMsg">{{iframeErrorMsg}}</span>
                                                    <span class="spinner-border spinner-border mx-1 text-primary" role="status" aria-hidden="true" v-else-if="previewLoader"></span>
                                                </div>
                                                <iframe id="preview_template" :src="iframeUrl" frameborder="0" height="100%" width="100%" v-if="!previewLoader && !iframeErrorMsg && iframeUrl"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-4 mb-2">
                                        <div class="d-flex justify-content-end">
                                            <div class="d-none">
                                                <label for="enabled_reviews" class="form-label mt-2 me-2" style="cursor:pointer">
                                                    Enable Preview
                                                </label>
                                                <div class="">
                                                    <div class="form-check form-switch">
                                                        <input
                                                            style="cursor:pointer"
                                                            id="enabled_reviews"
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            v-model="enabledPreview"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="formFields.config.tags.length > 0 " >
                                    <div class="col-md-12">
                                        <hr />
                                    </div>
                                    <div class="col-md-12 pt-0 p-3">
                                        <label for="" class="form-label">
                                            {{ 'Reviews That Will Show in Widget According to your Selection' }}
                                        </label>

                                        <div class="row p-3 d-flex justify-content-center review-container">
                                            <ReviewsListing
                                                :reviews="reviews"
                                                :ignoreIds="formFields.config.removedReviews"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="viewCode">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <i
                        class="bi bi-x-lg close-icon"
                        data-bs-dismiss="modal"
                    ></i>
                    <div class="p-2">
                        <div class="mb-2">
                            <i class="bi bi-copy"></i>
                            <label for="" class="form-label">
                                <b>Copy this code and paste in the webpage</b>
                            </label>
                            <textarea
                                class="form-control"
                                style="border-style: dashed"
                                disabled
                                rows="5"
                                >
                                {{ scriptCode }}
                            </textarea>
                        </div>
                        <div class="d-flex justify-content-center">
                            <a
                                @click="copyToClipBoard"
                                href="javascript:;"
                                style="text-decoration: none"
                                >
                                <i class="bi bi-clipboard2"></i>
                                Click to copy the code
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="modal fade" id="preview">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content" style="height: 800px;">
                    <i class="bi bi-x-lg close-icon" style="right: 28px !important;" data-bs-dismiss="modal"></i>
                    <div class="p-2" style="height: 100%;">
                        <div class="d-flex justify-content-center iframe-loader" style="height: 800px; align-items :center;">
                            <span class="spinner-border spinner-border mx-1 text-primary" role="status" aria-hidden="true"></span>
                        </div>
                        <iframe id="preview_template" :src="iframeUrl" frameborder="0" height="100%" width="100%"></iframe>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import Loading from '../reviews/loading.vue';
import { VueMonacoEditor } from '@guolao/vue-monaco-editor'
import ReviewsListing from './reviewsListing.vue';

import yelpImage from "../../../assets/yelp.png"
import googleImage from "../../../assets/google.png"
import facebookImage from "../../../assets/facebook.png"
import noImage from "../../../assets/user2.png"

export default {
    name: 'WidgetEdit',
    components: {
        vSelect,
        Loading,
        VueMonacoEditor,
        ReviewsListing
    },
    data() {
        return {
            loading: false,
            formFields: {
                id: '',
                title: '',
                config: {
                    template: 'carousel',
                    tags: [],
                    removedReviews: [],
                    autoPlay:false,
                    autoPlayDelay:0,
                    paginationEnable:false,
                    loopEnable:true,
                    loadMore:true,
                    perPage:8,
                    sortField:'reviews.created_at',
                    sortType:'DESC',
                    card_text_color:'#000000;',
                    card_background_hover_color:'#ffffff',
                    card_background_color:'#ffffff',
                    tabsEnable:false,
                    ratingInfo:false,
                    customCssEnable:false,
                    customCss:"",
                    colorsCollection:'custom',
                    fontFamily:{
                        label:'Open Sans',
                        value:'Open Sans',
                    }
                },
            },
            colorsCollections:[],
            tags: [],
            reviews: [],
            scriptCode: '',
            isReviewLoading:false,
            iframeUrl:'',
            iframeErrorMsg:'',
            formLoading:false,
            reviewPerPage:4,
            reviewPage:1,
            customThemeImage:'',
            enabledPreview:true,
            timeOutId:'',
            previewLoaderTimeOutId:'',
            previewLoader:false,
            fonts: [
                { label: "Sans Serif", value: "sans-serif"},
                { label: "Monospace", value: "monospace"},
                { label: "Emoji", value: "emoji"},
                { label: "Cursive", value: "cursive"},
            ],
        }
    },
    validations: {
        formFields: {
            title: {
                required,
            }
        },
    },
    watch:{
        enabledPreview:function(val, oldVal){
            if(val)
            {
                this.handelPreviewWidget()
            }
        },
        'formFields.config.template':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.autoPlay':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.autoPlayDelay':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.paginationEnable':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.loopEnable':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.loadMore':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.perPage':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.sortField':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.sortType':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.card_text_color':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.card_background_hover_color':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.card_background_color':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.tabsEnable':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.ratingInfo':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.customCssEnable':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.colorsCollection':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    if(v != o)
                    {
                        this.loadPreview()
                    }
                }
            },
            deep: true
        },
        'formFields.config.removedReviews':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    this.loadPreview()
                }
            },
            deep: true
        },
        'formFields.config.fontFamily':{
            handler:function(v, o){
                if(this.enabledPreview)
                {
                    this.loadPreview()
                }
            },
            deep: true
        },
        
    },
    created() {
        this.fetchTags()

        this.getUserWidget(this.$route.params.id).then((resp) => {
            this.isReviewLoading = true
            if(resp.status)
            {
                this.formFields = {
                    id: resp.data.id,
                    title: resp.data.title,
                    config: {
                        ...this.formFields.config,
                        ...resp.data.config
                    },
                }
                let temp = this 
                setTimeout(function(){
                    temp.reviews = resp.reviews
                    temp.isReviewLoading = false
                    temp.appendReviews(true)
                }, 500)
                this.setScript(resp.data.unique_id)
            }
        })

        $('body').on('click','.remove-review', this.removeReview)
        $('body').on('click','.restore-review', this.restoreReview)
        
        this.getSetting(['default_color_collection','colors_collections']).then((resp) => {
            this.colorsCollections = resp.colors_collections
            this.formFields.config.colorsCollection = !this.formFields.config.colorsCollection ? resp.default_color_collection : this.formFields.config.colorsCollection
        })
        this.customThemeImage = `${process.env.VUE_APP_BASEUPLOADURL}widget/images/theme/custom_theme.png`
        
    },
    mounted:function(){
        let removeEventListener = function(){
            document.removeEventListener("contextmenu",window.$disableRightClick, false);
        } 
        window.onload = removeEventListener
        removeEventListener()

        /* window.addEventListener("message",(event) => {
            console.log(event,"message")
        }); */
    },
    beforeRouteLeave:function(to, from , next) {
        document.addEventListener("contextmenu",window.$disableRightClick, false);
        $('body').off('click','.remove-review', this.removeReview)
        $('body').off('click','.restore-review', this.restoreReview)

        next()
    },
    methods: {
        ...mapActions([
            'getSetting',
            'getUserWidget',
            'updateUserWidget',
            'previewUserWidget',
            'getUserReviewsWithTags'
        ]),
        handelUpdateWidget: function () {
            this.$v.formFields.$touch()
            if (!this.$v.formFields.$invalid) 
            {
                if(this.formFields.config.tags.length > 0)
                {
                    if(this.formFields.config.perPage > 0)
                    {
                        this.formLoading = true
                        this.updateUserWidget(this.formFields).then((resp) => {
                            if(resp.status) 
                            {
                                this.notify(resp.message)
                                /* $('body').find('#viewCode').modal('show')
                                this.setScript(resp.id) */
                                this.setScript(resp.id)
                            }
                            else
                            {
                                this.notify(resp ? resp.message : 'Something went wrong please try again', 'danger')
                            }
                            this.formLoading = false
                        })
                    }
                    else
                    {
                        this.notify("Please Add 'View in one load more' in postive number ", 'danger')
                    }
                }
                else
                {
                    this.notify('Please Select atlest one tag', 'danger')
                }
            }
        },
        setScript: function (uniqueId) {
            this.getSetting('widget_script_code_template').then((resp) => {
                if (resp.widget_script_code_template) {
                    this.scriptCode = resp.widget_script_code_template.replace(
                        '{widget_unique_id}',
                        uniqueId
                    )

                    this.scriptCode = this.scriptCode.replace(
                        '{widget_title}',
                        this.formFields.title
                    )
                }
            })
        },
        handelTags: function (e) {
            this.reviews = []
            let { checked, value } = e.target
            let tags = this.formFields.config.tags
            if(checked)
            {
                tags.push(value)
            }
            else
            {
                let index = tags.indexOf(value)
                if(index >= 0)
                {
                    tags.splice(index, 1)
                }
            }
            this.onTagSelect()
            this.loadPreview()
        },
        notify: function (msg, type = 'success') {
            if(type)
            {
                this.$notify(msg, type)
            }
            else
            {
                this.$notify(msg)
            }
        },
        onTagSelect: function () {
            if (this.formFields.config.tags.length > 0) {
                this.isReviewLoading = true
                this.getUserReviewsWithTags(this.formFields.config.tags).then(
                    (resp) => {
                        this.reviews = []
                        if (resp.status) 
                        {
                            this.reviews = resp.data
                            this.reviewPage = 1;
                            this.appendReviews(true)
                        }
                        this.isReviewLoading = false
                    }
                )
            }
        },
        removeReview: function (e) {
            let that = $(e.target);
            let id = that.data('id')
            let index = that.data('index')
            let parentElement = that.parents('tr')
            this.formFields.config.removedReviews.push(id)
            // parentElement.remove()
        },
        restoreReview: function (e) {
            let that = $(e.target);
            let id = that.data('id')
            let index = this.formFields.config.removedReviews.indexOf(id)
            if(index >= 0 )
            {
                this.formFields.config.removedReviews.splice(index, 1)
            }
        },
        fetchTags: function () {
            this.$helper
            .getRequest('users/tags-reviews-relation/users-selected-tags')
                .then((resp) => {
                    if (resp.status) {
                        this.tags = resp.data
                        this.setTagsNote();
                    }
                })
        },
        copyToClipBoard: function () {
            navigator.clipboard.writeText(this.scriptCode)
            this.notify('Code copied to clipboard ')
        },
        reviewHtml:function(review){
            let platform = this.getPlatform(review.platform ? review.platform.toLowerCase() : '')
            let rating = this.getRating(review.rating)
            let avatar = review.avatar ? review.avatar : noImage;
            let html = `
                <div class="col-md-2 m-2 p-3 rounded border position-relative">
                    <div class="d-flex justify-content-center">
                        <img
                            class="img-thumbnail"
                            alt="avatar"
                            src="${avatar}"
                            style=" width: 40px; height: 40px; border-radius: 50% !important;"
                            />
                    </div>
                    <div class="d-flex justify-content-center">
                        <h6>
                            ${review.author}
                        </h6>
                    </div>
                    <div class="d-flex justify-content-center">
                        ${rating}
                        ${platform}
                    </div>
                    <i data-id="${review.id}" class="bi bi-x-circle-fill position-absolute cursor-pointer remove-review" style="right: 6px; top: 2px;">
                    </i>
                </div>`
            return html
        },
        getPlatform:function(platform){
            let platforms = {
                'yelp':`<img src="${yelpImage}" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />`,
                'google':`<img src="${googleImage}" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />`,
                'facebook':`<img src="${facebookImage}" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px;" />`,
            }

            return platforms[platform]
        },
        getRating:function(rating){
            let ratings = {
                '1':`
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    `,
                '2':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    `,
                '3':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                    `,
                '4':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    `,
                '5':`
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    `,
            }

            return ratings[rating]
        },
        appendReviews:function(replace = false){
            return true
            if(document.querySelector('.review-container'))
            {
                let html = '';
                let from = (this.reviewPage-1)*this.reviewPerPage;
                let to   = this.reviewPage*this.reviewPerPage
                let reviews = this.reviews.slice(from, to)
                
                reviews.map((item, index) => {
                    html = `${html} ${this.reviewHtml(item)} `
                })

                if(replace)
                {
                    document.querySelector('.review-container').innerHTML = html
                }
                else
                {
                    document.querySelector('.review-container').insertAdjacentHTML('beforeend', html)
                }
                
                if(this.reviews.length > to)
                {
                    this.reviewPage++
                    let temp = this
                    setTimeout(function(){
                        temp.appendReviews()
                    },100)
                }
            }
        },
        setTagsNote:function(){
            setTimeout(function(){
                let note = document.querySelector('.custom_tag_not_found');
                let defaultNote = document.querySelector('.default_tag_not_found');
                if(document.querySelector('.custom_tag_input'))
                {
                    note.classList.add('d-none')
                }
                else
                {
                    note.classList.remove('d-none')
                }

                if(document.querySelector('.default_tag_input'))
                {
                    defaultNote.classList.add('d-none')
                }
                else
                {
                    defaultNote.classList.remove('d-none')
                }
            },500)
        },
        handelPreviewWidget: function () {
            this.iframeUrl = `${process.env.VUE_APP_BASEUPLOADURL}co/widget/removecheck/preview`
            if(this.formFields.config.tags.length >= 0)
            {
                if(this.formFields.config.perPage > 0)
                {
                   /*  $('iframe#preview_template').addClass('d-none')
                    $('.iframe-loader').removeClass('d-none')
                    $('iframe#preview_template').on('load',function(){
                        $('iframe#preview_template').removeClass('d-none')
                        $('.iframe-loader').addClass('d-none')
                    }) */
                    this.formFields.config.colorsCollection = 'custom'
                    this.previewUserWidget({
                        ...this.formFields,
                        'title':this.formFields.title ? this.formFields.title : 'test'
                    }).then((resp) => {
                        if (resp.status)
                        {
                            this.iframeErrorMsg = '';
                            this.iframeUrl = resp.url
                            // $('body').find('#preview').modal('show')
                        }
                        else
                        {
                            this.iframeErrorMsg = resp ? resp.message : 'Something went wrong please try again'
                            // this.notify( resp ? resp.message : 'Something went wrong please try again','danger')
                        }
                    })
                }
                else
                {
                    this.iframeErrorMsg = "Please Add 'View in one load more' in postive number";
                    // this.notify("Please Add 'View in one load more' in postive number ", 'danger')
                }
            }
            else
            {
                this.iframeErrorMsg = "Please Select atlest one tag to see preview"
                // this.notify('Please Select atlest one tag to see preview', 'danger')
            }
        },
        onChangeEditor:function(){
            this.loadPreview()    
        },
        loadPreview:function(){
            if(this.enabledPreview)
            {
                this.previewLoader = true
                if(this.timeOutId)
                {
                    clearTimeout(this.timeOutId);   
                }
                let temp = this
                this.timeOutId = setTimeout(function(){
                    temp.handelPreviewWidget()
                    if(temp.previewLoaderTimeOutId)
                    {
                        clearTimeout(temp.previewLoaderTimeOutId)   
                    }
                    temp.previewLoaderTimeOutId = setTimeout(function(){
                        temp.previewLoader = false
                    },500)
                },1000)
            }
        }
    }
}
</script>

<style scoped>
.active-check {
    display: none;
}

.layout-style.active {
    border: 2px solid #0d6efd !important;
    position: relative;
}

.layout-style.active .active-check {
    display: block !important;
    position: absolute;
    top: -13px;
    right: -9px;
    color: #0d6efd;
}

body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: visible !important;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

@media (min-width: 992px) {
    .user-card-full .user-profile-div {
        border-radius: 5px 0 0 5px;
    }
}

.bg-c-lite-green {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f29263),
        to(#ee5a6f)
    );
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.required {
    color: red;
}
.popup-logo {
    max-height: 120px;
    image-rendering: -webkit-optimize-contrast;
    max-width: 100px;
    height: auto;
    width: auto;
    top: 6px;
    border: solid;
}
.selected-logo {
    object-fit: contain;
    max-width: 100% !important;
    max-height: 149px;
    min-width: 149px;
    min-height: 149px;
}

.star {
    color: gold;
    font-size: 16px;
}

.template-div {
    position: relative;
    cursor: pointer;
}

.template-card{
    padding:1%;
    width: 200px;
}
.template-card.active{
    border-color: black;
    border-width: 3px;
    border-style: solid;
}
.selected-text {
    position: absolute;
    left: 85px;
    top: -17px;
}

.preview-enable {
    border-width:thin;
    border-radius:5px;
    border-style:dashed;
    border-color:aquamarine;
}

@-moz-document url-prefix() {
    hr{
        height: 2px !important;
    }
}

@media screen and (min-width: 990px) {
    .template-div {
        margin: 3%;
    }
}

@media screen and (min-width: 1300px) {
    .template-div {
        margin: 1%;
    }
}
</style>
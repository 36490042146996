<template>
  	<div ref="anyName" class="col-10 mx-auto mt-5 blog-post-creator card p-5 shadow-lg border rounded blog-post-creator">
    	<h4 class="m-2" style="text-align: center">Edit Feed </h4>
		<form class="m-3 p-3">
			<div class="" >
				<div class="title-input" v-if="!isReviewFeed">
					<input 
						class="form-control"
						v-model="forms.title"
						placeholder="Enter a post title"
						:class="{ 'is-invalid': $v.forms.title.$error }"/>
					<p v-if="$v.forms.title.$error" class="text-left pt-1" style="color: red">
						Post must have a title
					</p>
				</div>
				
				<div class="select-media-type mt-2 ms-1 py-2" v-if="feedData.type != 'review' && feedData.type != 'external' ">
					<label>Select Media Type</label>
					<div class="d-flex gap-3 align-items-center">
						<div class="media-image d-flex gap-2 align-items-center" role="button">
							<input type="radio" role="button" name="select-media" id="select-image" value="image" :checked="forms.mediaType == 'image'" @change="changeMediaType">
							<label for="select-image" role="button">Image</label>
						</div>
						<div class="media-video d-flex gap-2 align-items-center" role="button">
							<input type="radio" role="button" name="select-media" id="select-video" value="video" :checked="forms.mediaType == 'video'" @change="changeMediaType">
							<label for="select-video" role="button">Video </label>
						</div>
						<div class="media-video d-flex gap-2 align-items-center" role="button">
							<input type="radio" role="button" name="select-media" id="select-url" value="url" :checked="forms.mediaType == 'url'" @change="changeMediaType">
							<label for="select-url" role="button">Embed Url</label>
						</div>
					</div>
					<p v-if="$v.forms.mediaType.$error" class="text-left" style="color: red">
						Please select type of media
					</p>   
				</div>
				
				<div class="inner-image-input py-2 gap-2 d-flex" v-if="forms.mediaType == 'image' && feedData.type != 'review' ">
					<div class="image-input">
						<label class="ivu-btn ivu-btn-primary" for="postMedia" icon="ios-camera-outline">Add Image
							<input type="file" id="postMedia" accept="image/*" @change="uploadMedia" style="display: none"/>
						</label>
						<div v-if="image_url == ''" class="mt-1">
							<small class="text-danger font-monospace">1. Max upload size: 5 mb</small>
						</div>
						<div v-if="image_url== ''" class="mt-1">
							<small class="text-danger font-monospace">2. Accepted Extensions (jpg or jpeg, png)</small> 
						</div>    
						<p v-if="$v.forms.file.$error" class="text-left" style="color: red">
							Post must have an image
						</p>
					</div>
					<div class="preview">
						<img :src="image_url" width="40px" class="mx-2" height="40px" v-if="image_url"/>
					</div>
				</div>

				<div class="inner-video-input py-2 gap-2 d-flex" v-if="forms.mediaType == 'videoOld' && feedData.type != 'review'">
					<div class="video-input">
						<label class="ivu-btn ivu-btn-primary" for="postMedia" icon="ios-camera-outline">
							Add Video
							<input type="file" id="postMedia" accept="video/*" @change="uploadMedia" style="display: none"/>
						</label>   
						<div v-if="mediaSrc == ''" class="mt-1">
							<small class="text-danger font-monospace">1. Max upload size: 50 mb</small> 
						</div>  
						<div v-if="mediaSrc== ''" class="mt-1">
							<small class="text-danger font-monospace">2. Accepted Extensions (mov, mp4)</small> 
						</div>  
						<p v-if="$v.forms.media.$error" class="text-left" style="color: red">
							Post must have an video
						</p>
					</div>
					<div class="video-video-preview">
						<video width="200" controls v-if="mediaSrc">
							<source :src="mediaSrc" id="media_preview">
							Your browser does not support HTML5 video.
						</video>
					</div>
				</div>

				<div class="inner-video-input py-2 gap-2 d-flex" v-if="forms.mediaType == 'video' && feedData.type != 'review' && feedData.type != 'external'">
					<div class="col-md-12 video-upload-div">
						<div class="d-flex justify-content-between">
							<div class="">
								<h6>Upload Video By Platforms</h6>
							</div>	
							<div class="">
								<button class="btn btn-info btn-sm text-white" type="button" @click="changeVideoMode" >
									<i class="bi bi-gear me-1"></i> 
									{{ videoValidateMode == 'SIMPLE' ? 'Switch to advance mode' : 'Switch to simple mode' }}
								</button>
							</div>
						</div>
						<div class="row" for="simple_mode" :class="[videoValidateMode != 'SIMPLE' ? 'd-none' : '']">
							<div class="col-md-4">
								<label for="">
									Connected Accounts
								</label>
								<template>
									<div class="d-flex align-items-center flex-wrap platforms">
										<template v-if="user.ayr_connected_accounts">
											<div class="form-check" v-for='(item, index) of user.ayr_connected_accounts ? JSON.parse(user.ayr_connected_accounts) : []' :key="index">
												<div v-tooltip="$ucFirst(item)" class="platform-icons">
													<label>
														<input 
															type="checkbox"
															:value="item"
															@change="handleCheckSocialAccounts"
															:checked="selectedPlatforms[videoValidateMode].includes(item) ? true :false"
															/>
														<span>
															<img :src="getPlatformImage(item)" alt="">
														</span>
													</label>
												</div>
											</div>
										</template>
										<template v-else>
											<span class="text-danger">Connected social media accounts not found.</span>
										</template>
									</div>
								</template>
							</div>
							<div class="col-md-8">
								<label for="">
									Validate Video
								</label>
								<div class="video-validate-outer-area">
									<div class="video-validate-inner-area" @drop="dropHandler" @dragover="dragOverHandler" @dragleave="dropLeave">
										<template v-if="!validateVideo[videoValidateMode]">
											<div class="w-100 d-flex justify-content-center mt-2" v-if="isDragOver">
												<label for="" style="font-size:xxx-large">Drop the file</label>
											</div>
											<div class="w-100 d-flex justify-content-center" v-if="!isDragOver">
												<button class="btn btn-primary btn-sm d-flex" type="button" @click="openVideoUploader">
													<i class="bi bi-upload me-1" v-if="!validateLoader"></i>
													<Loading style="margin-top: 2px;" v-if="validateLoader"/>
													{{ validateLoader ? loadingText : "Validate Video" }} 
												</button>
											</div>	
											<div class="w-100 d-flex justify-content-center mt-2" v-if="!isDragOver">
												<label for="">--OR--</label>
											</div>
											<div class="w-100 d-flex justify-content-center mt-1" v-if="!isDragOver">
												<label for="">Drag & Drop video inside to validate video for selected platforms</label>
											</div>
										</template>
										<template v-else-if="validateVideo[videoValidateMode]">
											<div class="w-100 d-flex justify-content-center position-relative">
												<i @click="deleteValidateVideo" v-if="!validateLoader && !loadingText" class="bi bi-trash del_btn_video"></i>
												<Loading class="del_btn_video" style="margin-top: 2px;" v-if="validateLoader && !loadingText"/>
												<video :src="validateVideo[videoValidateMode]" style="height: 220px;" controls></video>
											</div>
											<template v-if="validateVideo[videoValidateMode] && successPlatforms[videoValidateMode].length == 0 && errorPlatforms[videoValidateMode].length == 0">
												<div class="w-100 d-flex justify-content-center mt-3">
													<Loading style="margin-top: 2px;" v-if="validateLoader && loadingText"/>
													<label><strong>{{loadingText}}</strong></label>
												</div>
											</template>
											<template v-else>
												<template v-if="successPlatforms[videoValidateMode].length > 0">
													<div class="w-100 d-flex justify-content-center mt-3">
														<label for=""><strong>Successfully validated for following platforms</strong></label>
													</div>
													<div class="w-100 d-flex justify-content-center mt-1">
														<img 
															v-for="platform in successPlatforms[videoValidateMode]"
															:src="getPlatformImage(platform)" 
															style="height:20px"
															class="ms-1 me-1"
															>
													</div>
												</template>
												<template v-if="errorPlatforms[videoValidateMode].length > 0">
													<div class="w-100 d-flex justify-content-center mt-3">
														<label for=""><strong>Failed to validate these following platform (hover to check the error)</strong></label>
													</div>
													<div class="w-100 d-flex justify-content-center mt-1">
														<img
															v-for="platform in errorPlatforms[videoValidateMode]" 
															:src="getPlatformImage(platform)" 
															v-tooltip="validateErrors[videoValidateMode][platform] ? validateErrors[videoValidateMode][platform] : '-'"
															class="ms-1 me-1"
															style="height:20px"
															>
													</div>
												</template>
											</template>
										</template>
									</div>
								</div>
							</div>
						</div>
						<div class="row" for="advanced_mode" :class="[videoValidateMode != 'ADVANCED' ? 'd-none' : '']">
							<div class="col-md-2">
								<label for="" class="mb-2">
									Connected Accounts
								</label>
								<template v-if="user.ayr_connected_accounts">
									<div class="list-group box-shadow mt-1" v-for="platform in user.ayr_connected_accounts ? JSON.parse(user.ayr_connected_accounts) : []">
										<button 
											@click="setRecommendation(platform)"
											type="button"
											class="list-group-item list-group-item-action" 
											:class="[selectedRecommendations.slug == platform.toLowerCase() ? 'active' : '']"
											>
											{{ platform == 'gmb' ? 'Google Business' : platform == 'fbg' ? 'Facebook Business' : $ucFirst(platform)}}
										</button>
									</div>
								</template>
								<template v-else>
									<span class="text-danger">Connected social media accounts not found</span>
								</template>
							</div>
							<div class="col-md-5">
								<button 
									@click="deleteValidateVideo"
									v-if="selectedRecommendations.slug && validateVideo[videoValidateMode][selectedRecommendations.slug] && !loadingText"
									class="btn btn-danger w-100 mb-2 box-shadow" 
									type="button">
									<i class="bi bi-trash me-1" v-if="!validateLoader"></i>
									<Loading style="margin-top: 2px;" v-if="validateLoader"/>
									Delete
								</button>
								<button 
									v-else
									class="btn w-100 mb-2 box-shadow" 
									@click="openVideoUploader"
									type="button">
									<i class="bi bi-upload me-1" v-if="!validateLoader"></i>
									<Loading style="margin-top: 2px;" v-if="validateLoader"/>
								 	{{ validateLoader ? loadingText : "Validate & Upload Video" }}
								</button>
								<div class="inner-video-upload-section-advanced">
									<label for=""><strong>Recommendations</strong></label>
									<ul style="list-style: none; padding-left: 0;">
										<li v-for="item in selectedRecommendations.data" v-html="item"></li>
									</ul>
								</div>
								<template v-if="successPlatforms[videoValidateMode].length > 0">
									<div class="w-100 d-flex justify-content-center mt-3">
										<label for=""><strong>Successfully validated for following platforms</strong></label>
									</div>
									<div class="w-100 d-flex justify-content-center mt-1">
										<img 
											v-for="platform in successPlatforms[videoValidateMode]"
											:src="getPlatformImage(platform)" 
											style="height:20px"
											class="ms-1 me-1"
											>
									</div>
								</template>
								<template v-if="errorPlatforms[videoValidateMode].length > 0">
									<div class="w-100 d-flex justify-content-center mt-3">
										<label for=""><strong>Failed to validate these following platform (hover to check the error)</strong></label>
									</div>
									<div class="w-100 d-flex justify-content-center mt-1">
										<img
											v-for="platform in errorPlatforms[videoValidateMode]" 
											:src="getPlatformImage(platform)" 
											v-tooltip="validateErrors[videoValidateMode][platform] ? validateErrors[videoValidateMode][platform] : '-'"
											class="ms-1 me-1"
											style="height:20px"
											>
									</div>
								</template>
							</div>
							<div class="col-md-5">
								<label for="">
									Validate Video
								</label>
								<video 
									controls 
									style="width: 485px; height: 209px;" 
									v-if="validateVideo[videoValidateMode][selectedRecommendations.slug]" 
									:src="validateVideo[videoValidateMode][selectedRecommendations.slug]"
									>
								</video>
								<div v-else style="
									display:flex;
									justify-content:center;
									padding-top:90px;
									background-color: black;
									color: white;
									width: 485px;
									height: 209px;
								">
									<span>Please Upload a Video</span>
								</div>
							</div>
						</div>
						<input type="file" class="validate_video_upload d-none" @change="validateVideoSimple"/>
					</div>
				</div>

				<div class="mb-2" v-if="forms.mediaType == 'url' && feedData.type != 'review' || (feedData.type == 'external' && forms.mediaType == 'video')">
					<label v-if="feedData.type == 'external'" class="mt-2 mb-1">Embed Url</label>
					<label class="w-100" for="postMedia" icon="ios-camera-outline">
						<input 
							type="url"
							placeholder="Embed url"
							class="form-control"
							:class="{ 'is-invalid': (!isValid && embed_url!='') || $v.forms.embed_url.$error }" 
							v-model="embed_url" 
							@input="uploadMedia"/>
						<p v-if="!isValid && embed_url!=''" class="mt-1 text-danger">
							{{ feedData.type == 'external' ? "Please enter a valid youtube url." : "Please enter a valid url." }} 
						</p>
						<p v-if="$v.forms.embed_url.$error" class="mt-1 text-left" style="color: red">
							Post must have an Embed video Url
						</p>
					</label>
					<div v-if="feedData.type != 'external' && forms.mediaType== 'url'" class="mt-1">
						<small class="text-danger font-monospace">
							1. URL with extension mp4, mov, jpg or jpeg, png will be embedded on social media. For other type of URL, it will be attached to the post as a link.
						</small>
					</div>
					<div v-if="feedData.type != 'external' && forms.mediaType== 'url'">
						<small class="text-danger font-monospace">
							2. For Image URL, size of the file should not be more than 5 MB
						</small>
					</div>
					<div v-if="feedData.type != 'external' && forms.mediaType== 'url'">
						<small class="text-danger font-monospace">
							3. For video URL,  size of the file should not be more than 50 MB
						</small>
					</div>
				</div>
				
				<div class="inner-image-input py-2 gap-2 d-flex" v-if="feedData.type == 'review'">
					<div class="preview">
						<label class="mx-2 mb-2">Review Image</label>
						<br>
						<img @click="showImgInPopup(image_url)" :src="image_url" style="cursor: pointer;" class="mx-2 img-thumbnail" width="100px" height="100px"/>
					</div>
				</div>
			</div>
			
			<div class="mb-3" v-if="isReviewFeed">
				<label for="feed_introductory_text" class="form-label">Feed Introductory Text</label>
				<!-- <textarea class="form-control" id="feed_introductory_text" rows="3" v-model="reviewData.introductory_text"></textarea> -->
				<quill-editor
					id="feed_introductory_text"
					v-model="reviewData.introductory_text"
					:options="editorOption"
					:class="{ 'is-invalid': $v.reviewData.introductory_text.$error }"
				/>
				<small v-if="$v.reviewData.introductory_text.$error" class="text-left" style="color: red">
					Testimonial feed must have a Introductory Text
				</small>
			</div>
			
			<div class="mb-2 mt-3">
				<label for="description" class="form-label">Description</label>
				<quill-editor
					v-model="forms.content"
					ref="myQuillEditor"
					@change="onEditorChange($event)"
					:class="[($v.forms.content.$error || (reviewDescriptionLimit && contentCount && contentCount > reviewDescriptionLimit)) ? 'is-invalid' : '' ]"
					:options="editorOption"
				/>
				<div class="d-flex justify-content-between" v-if="isReviewFeed">
					<small v-if="!($v.forms.content.$error && !$v.forms.content.required) && !(reviewDescriptionLimit &&  contentCount && contentCount > reviewDescriptionLimit)"></small>
					<small v-if="$v.forms.content.$error && !$v.forms.content.required" class="text-left" style="color: red">
						Testimonial feed must have a description
					</small>
					<small v-if="reviewDescriptionLimit && contentCount && contentCount > reviewDescriptionLimit" class="text-left" style="color: red">
						Please ensure your review text does not exceed the character limit of {{ reviewDescriptionLimit }}.
						Exceeding this limit may cause the review post image to display incorrectly. 
						Kindly keep your review concise and within the limit.
					</small>

					<small>Total Words: {{contentCount}}</small>
				</div>
				<div v-else>
					<small v-if="$v.forms.content.$error && !$v.forms.content.required" class="text-left" style="color: red">
						Post must have a description
					</small>
				</div>
				
			</div>

			<div class="mb-3" v-if="isReviewFeed && (isOrignalReviewEdited || isReviewShortDescriptionGenerated) && reviewData.original_review">
				<label for="original_review" class="form-label">Original Review</label>
				<p id="original_review" style="background-color: #e9ecef; padding: 7px; border-radius: 8px;">{{reviewData.original_review}}</p>
			</div>
			
			<div class="mt-3">
				<button 
					class="ivu-btn ivu-btn-primary"
					@click="save"
					v-if="isReviewFeed"
					:disabled="feedData.id && isReviewFeed && isReviewAboveThenLimit == false ? true : false"
					>
					{{ (isReviewFeed && isDescriptionChanged) ? 'Save & Re-Generate Image' : 'Save' }}
					<span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
				</button>
				<button 
					class="ivu-btn ivu-btn-primary"
					@click="save"
					v-else
					:disabled="isLoading || validateLoader"
					>
					{{ (isReviewFeed && isDescriptionChanged) ? 'Save & Re-Generate Image' : 'Save' }}
					<span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
				</button>
				<button class="ivu-btn ivu-btn-secondary mx-2" @click="Cancel">
					Cancel
				</button>
			</div>
		</form>

		<div class="modal fade" id="showImgInPopup" tabindex="-1" aria-labelledby="showImgInPopupLabel" aria-hidden="true" >
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-transparent border-0">
                    <div class="modal-body px-0" style="width: fit-content; margin: 0 auto; z-index: 0">
                        <div class="popup-img-div">
                            <div class="popup-d w-fit-content m-auto position-relative">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close btn btn-close custom-btn bg-white p-3"></button>
                                <img class="h-auto img-fluid" :src="showImgInPopUpSrc" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import axios from "axios";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import "iview/dist/styles/iview.css";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import Loading from "./reviews/loading.vue";

import yelpImage from "../../assets/yelp.png"
import googleImage from "../../assets/google.png"
import facebookImage from "../../assets/facebook.png"
import twitterImage from "../../assets/twitter.png"
import linkedinImage from "../../assets/linkedin.png"
import instagramImage from "../../assets/instagram.png"
import pinterestImage from "../../assets/pinterest.png"
import telegramImage from "../../assets/telegram.png"
import googleBusinessImage from "../../assets/google_business.png"
import facebookGroupsImage from "../../assets/facebook_groups.png"

export default {
	name: "AddPost",
	components: {
		quillEditor,
		Loading
	},
	computed: {
		isReviewFeed: function(){
			return this.feedData && this.feedData.type == 'review' ? true : false;
		},
		isOrignalReviewEdited:function(){
			if(this.isReviewFeed)
			{
				return this.reviewData.edited_review ? true :false;
			}
		},
		isReviewShortDescriptionGenerated:function(){
			if(this.isReviewFeed)
			{
				return this.reviewData.review_short_description ? true :false;
			}
		},
		isClonned:function(){
			return this.$route.query.IC == 'true' ? true : false;
		},
		isToBeClonned:function(){
			return this.$route.query.ITBC == 'true' ? true : false;
		}
	},
	watch:{
		videoValidateMode:function(val, oldval){
			if(val == 'ADVANCED')
			{
				if(!(this.selectedRecommendations && this.selectedRecommendations.slug))
				{
					if(this.user && this.user.ayr_connected_accounts)
					{
						let accounts = JSON.parse(this.user.ayr_connected_accounts)
						if(accounts && accounts.length > 0)
						{
							this.setRecommendation(accounts[0])
						}
					}
				}
			}
		}
	},
	data() {
		return {
			embed_url:'',
			isValid:'',
			time1: "",
			feedId: "",
			con: "",
			user: {},
			editorOption: {
				placeholder: "Type your post...",
				readOnly: true,
				theme: "snow",
				modules: {
					toolbar: [
						['bold', 'italic']
					],
				}
			},
			isLoading: false,
			isLoading1: false,
			forms: {
				title: "",
				file: "",
				embed_url:"",
				media: "",
				mediaType: "",
				content: "",
			},
			image_url: "",
			mediaSrc: "",
			typeOptions: [
				{
				name: "external",
				value: "external",
				},
				{
				name: "education",
				value: "education",
				},
				{
				name: "inspirational",
				value: "inspirational",
				},
				{
				name: "funny",
				value: "funny",
				},
				{
				name: "observances",
				value: "events",
				},
			],
			feedData:{},
			postIntroductoryText:'',
			isReviewAboveThenLimit:true, //Default Value,
			originalReview:'',
			allRecommendations:[
				{
					"platform":"GMB",
					"slug":"gmb",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Duration Max</strong> : Up to 30 seconds long',
						'<strong>Video Resolution</strong> : 720p or higher.'
					]
				},
				{
					"platform":"X",
					"slug":"twitter",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Duration </strong>: Must be between 0.5 seconds and 140 seconds.',
						'<strong>Video Resolution </strong>: 1280x720 px.'
					]
				},
				{
					"platform":"Facebook",
					"slug":"facebook",
					"data":[
						'<strong>Max video size </strong> : 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Video Resolution </strong>: 1280 x 720 for landscape and portrait.'
					]
				},
				{
					"platform":"Facebook Business",
					"slug":"fbg",
					"data":[
						'<strong>Max video size </strong> : 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Video Resolution </strong>: 1280 x 720 for landscape and portrait.'
					]
				},
				{
					"platform":"LinkedIn",
					"slug":"linkedin",
					"data":[
						'<strong>Max video size </strong> : 50 MB',
						'<strong>Supported formats</strong> : MP4',
						'<strong>Duration max </strong>: 30 minutes.',
						'<strong>Duration min </strong>: 3 seconds.',
						'<strong>Aspect ratio </strong>: Must be between 1:2.4 and 2.4:1.'
					]
				},
				{
					"platform":"Telegram",
					"slug":"telegram",
					"data":[
						'<strong>Max video size </strong>: 20 MB',
						'<strong>Supported formats </strong>: MP4'
					]
				},
				{
					"platform":"Instagram",
					"slug":"instagram",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats</strong> : MP4',
						'<strong>Duration max </strong>: 15 minutes.',
						'<strong>Duration min </strong>: 3 seconds.',
						'<strong>Video Resolution </strong>: 1920'
					]
				},
				{
					"platform":"Pinterest",
					"slug":"pinterest",
					"data":[
						'<strong>Max video size </strong>: 50 MB',
						'<strong>Supported formats </strong>: MP4',
						'<strong>Duration max </strong>: 15 minutes.',
						'<strong>Duration min </strong>: 4 seconds.',
						'<strong>Video Resolution </strong>: 1280 x 720 for landscape and portrait'
					]
				}
			],
			selectedRecommendations:{},
			videoValidateMode:'SIMPLE',//SIMPLE|ADVANCED,
			validateVideo:{
				"SIMPLE":"",
				"ADVANCED":{},
			},//https://api-socialfeed.rudraserver.com/uploads/temp/dummy_video.mp4
			selectedPlatforms:{
				"SIMPLE":[],
				"ADVANCED":[],
			},
			successPlatforms:{
				"SIMPLE":[],
				"ADVANCED":[],
			},
			errorPlatforms:{
				"SIMPLE":[],
				"ADVANCED":[],
			},
			validateErrors:{
				"SIMPLE":{},
				"ADVANCED":{},
			},
			validateLoader:false,
			isDragOver:false,
			reviewData:{
				edited_review:'',
				introductory_text:'',
				original_review:'',
				review_short_description:''
			},
			firstTimeDescription:'',
			isDescriptionChanged:false,
			contentCount:0,
			reviewDescriptionLimit:0,
			isCheckedMediaType:'',
			showImgInPopUpSrc:'',
			videoChunkData:{
				totalChunks:'',
				currentChunk:''
			},
			loadingText:''
		};
	},
	validations() {
		if (this.feedData.type == "review")
		{
			return {
				forms: {
					title: { 
						required
					},
					content: { 
						required,
						// maxLength:maxLength(150)
					}
				},
				reviewData:{
					introductory_text :{
						required
					}
				}
			};
		}
		else if (this.forms.mediaType == "image" && this.image_url == "") {
		return {
			forms: {
			title: { required },
			file: { required },
			embed_url:{},
			media: {},
			mediaType: { required },
			content: { required },
			},
		};
		} else if (this.forms.mediaType == "video" && this.mediaSrc == "") {
		return {
			forms: {
			title: { required },
			file: {},
			embed_url:{},
			// media: { required },
			mediaType: { required },
			content: { required },
			},
		};
		}else if(this.forms.mediaType =="url" && this.embed_url == ""){
		return {
			forms: {
			title: { required },
			file: {},
			embed_url:{required},
			media: {  },
			mediaType: { required },
			content: { required },
			},
		};
		} else {
		return {
			forms: {
			title: { required },
			file: {},
			embed_url:{},
			media: {},
			mediaType: { required },
			content: { required },
			},
		};
		}
	},
	async created() {
		
		this.id = this.$route.params.id;
		if(this.isClonned || this.isToBeClonned)
		{
			await this.getClonnedFeedData(this.id)
		}
		else
		{
			await this.getFeedData(this.id);
		}

		axios
		.get(process.env.VUE_APP_BASEURL + "/getupdateProfile", {
			headers: {
			"Content-type": "application/json",
			token: `${localStorage.getItem("token")}`,
			},
		})
		.then((res) => {
			//	console.log(res.data)
			if (res.status) {
				this.user = res?.data?.user_data;
				if(this.user == undefined)
				{
					this.user = {}
				}
			}
		})
		.catch(() => {});
		
		if(this.isReviewFeed)
		{
			await this.getOriginalReview(this.feedData.review_id).then((resp) => {
				if(resp.status)
				{
					this.reviewData = {
						edited_review:resp.data.edited_review,
						introductory_text:resp.data.introductory_text,
						original_review:resp.data.original_review,
						review_short_description:resp.data.review_short_description
					}

					this.reviewDescriptionLimit =  resp.review_description_limit
					/* this.isReviewAboveThenLimit = resp.isReviewAboveThenLimit
					this.originalReview = resp.original_text */
				}
			})

			if(!this.reviewData.introductory_text)
			{
				this.getSetting('post_introductory_text').then((resp) => {
					this.reviewData.introductory_text = resp.post_introductory_text ? resp.post_introductory_text.replace('%customer_name%', this.feedData.title) : ''
					if(this.reviewData.introductory_text)
					{
						this.fetchUserBusiness().then((resp) => {
							if(resp.status)
							{
								this.reviewData.introductory_text = this.reviewData.introductory_text.replace('%client_company%', resp.data.name)
							}
						})
					}
				})
			}
		}
	},
	mounted:function(){
		let removeEventListener = function(){
			document.removeEventListener("contextmenu",window.$disableRightClick, false);
		}
		window.onload = removeEventListener
		removeEventListener()
	},
	beforeRouteLeave:function(to, from, next) {
		document.addEventListener("contextmenu", window.$disableRightClick, false);
		next()
	},
	methods: {
		...mapActions([
			'getSetting',
			'fetchUserBusiness',
			'getOriginalReview'
		]),
		isURLValid(inputUrl) {
			if(this.feedData.type == 'external')
			{
				let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
				this.isValid = (inputUrl.match(p)) ? true : false;	
			}
			else
			{
				let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
				
				this.isValid = !!pattern.test(inputUrl);
			}
			
		},
		async getFeedData(id) {
			await axios
			.get(process.env.VUE_APP_BASEURL + `/get-custom-feed?feed_id=${id}`, {
				headers: {
					"Content-type": "application/json",
					token: `${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				let ab = JSON.parse(res.data?.data?.media);
			
				if(res.data.status)
				{
					let feedData = res.data?.data ? res.data?.data : {} 
					this.feedData = feedData
					this.con = res.data?.data?.body;
					this.firstTimeDescription = res.data?.data?.body
					this.forms.content = res.data?.data?.body.replace(/\r?\n|\r/g, '<br>');             
					this.forms.title = res.data?.data?.title;
					this.forms.mediaType = ab.type;
					if(feedData.type == 'review')
					{
						this.image_url = ab.url;
					}
					else
					{
						if(ab.type == "image")
						{
							this.image_url = ab.url;
						} 
						else if(ab.type=='url' && ab.url!=null)
						{
							this.embed_url=ab.url
							this.isValid = true
						}
						else
						{
							this.mediaSrc = ab.url;
						}
					}

					if(res.data?.data.video_data)
					{
						let videoData = JSON.parse(res.data?.data.video_data)
						if(videoData)
						{
							for(let key in videoData)
							{
								if(this[key] != undefined)
								{
									this[key] = videoData[key]
								}
							}
							if(this.videoValidateMode == 'ADVANCED')
							{
								if(this.successPlatforms.ADVANCED.length > 0)
								{
									this.setRecommendation(this.successPlatforms.ADVANCED[0])	
								}
								else if(this.errorPlatforms.ADVANCED.length > 0)
								{
									this.setRecommendation(this.errorPlatforms.ADVANCED[0])	
								}
							}
						}
					}
				}
			}).catch(() => {});
		},
		async getClonnedFeedData(id) {
			
			this.$helper.getRequest(`users/feed/${id}/clone-feed`).then((resp) => {
				
				let media = JSON.parse(resp.data.media);
				
				if(resp.status)
				{
					let feedData = resp.data

					this.feedData = feedData
					
					this.con = feedData.body;
					this.firstTimeDescription = feedData.body
					this.forms.content = feedData.body.replace(/\r?\n|\r/g, '<br>');             
					this.forms.title = feedData.title;
					this.forms.mediaType = media.type;
					
					if(media.type == "image")
					{
						this.image_url = media.url;
					} 
					else if(media.type == 'url' && media.url != null)
					{
						this.embed_url = media.url
						this.isValid = true
					}
					else if(this.feedData.type == 'external' && this.feedData.media_type == 'video')
					{
						this.embed_url = media.url
						this.isValid = true
					}
					else
					{
						this.mediaSrc = media.url;
					}
					

					if(feedData.video_data)
					{
						let videoData = JSON.parse(feedData.video_data)
						if(videoData)
						{
							for(let key in videoData)
							{
								if(this[key] != undefined)
								{
									this[key] = videoData[key]
								}
							}
							if(this.videoValidateMode == 'ADVANCED')
							{
								if(this.successPlatforms.ADVANCED.length > 0)
								{
									this.setRecommendation(this.successPlatforms.ADVANCED[0])	
								}
								else if(this.errorPlatforms.ADVANCED.length > 0)
								{
									this.setRecommendation(this.errorPlatforms.ADVANCED[0])	
								}
							}
						}
					}
				}
			}).catch(() => {});
		},
		onEditorChange({ quill, html, text }) {
			this.con = html;
			if(this.isReviewFeed)
			{
				if((!this.isOrignalReviewEdited || this.isOrignalReviewEdited) && !this.isReviewShortDescriptionGenerated)
				{
					this.reviewData.edited_review = text;
				}
				else if(this.isReviewShortDescriptionGenerated)
				{
					this.reviewData.review_short_description = text;
				}
			
				this.contentCount = html.length
				this.checkIsContentChanged(text)
			}
		},
		checkIsContentChanged:function(text){
			const removeTags = str => str.replace(/<\/?[^>]+(>|$)/g, "");
			
			const normalize = str => removeTags(str).replace(/\s+/g, "").trim();
			
			this.isDescriptionChanged = !(normalize(text) === normalize(this.firstTimeDescription))
		},
		change(event) {},
		close(event) {},
		changeMediaType(event) {
			this.$v.$reset();
			this.forms.mediaType = event.target.value;
			this.checkMediaType(event)
		},
		uploadMedia(event) {
		if (this.forms.mediaType == "image") {
			this.mediaSrc = "";
			this.forms.media = "";
			this.forms.file = event.target.files[0];
			var fileReader = new FileReader();
			fileReader.onload = (ev) => {
			this.image_url = ev.target.result;
			};
			fileReader.readAsDataURL(event.target.files[0]);
		}
		else if(this.forms.mediaType == "url"){
			this.mediaSrc = "";
			this.forms.media = "";      
			this.isURLValid(this.embed_url)
			//return;
		}  else {
			this.image_url = "";
			this.forms.file = "";
			this.forms.media = event.target.files[0];
			this.mediaSrc = URL.createObjectURL(event.target.files[0]);
			setTimeout(() => {
			$("#media_preview").parent()[0].load();
			}, 300);
		}
		},
		resetForm() {
		var self = this; //you need this because *this* will refer to Object.keys below`

		//Iterate through each object field, key is name of the object field`
		Object.keys(this.forms).forEach(function (key, index) {
			self.forms[key] = "";
		});
		},
		save(e) {
			// TODO
			e.preventDefault();
			this.$v.$touch();
			if ( this.embed_url != '' && this.isValid == false ){
				return false;
			}
			//	console.log(this.forms.title,this.forms.content,this.file)
			if (!this.$v.$invalid ) {
			
				this.isLoading = true;

				let formData = new FormData();
				if (this.forms.file != "") {
				formData.append("image", this.forms.file);
				}
				if (this.forms.media != "") {
				formData.append("media", this.forms.media);
				}
				if(this.embed_url!=''){
				formData.append("embed_url", this.embed_url);
				}
				formData.append("mediaType", this.forms.mediaType);
				formData.append("feed_id", this.id);
				formData.append("title", this.forms.title);
				formData.append("body", this.con);

				let obj = {
					"validateVideo":this.validateVideo,
					"videoValidateMode":this.videoValidateMode,
					"selectedPlatforms":this.selectedPlatforms,
					"successPlatforms":this.successPlatforms,
					"errorPlatforms":this.errorPlatforms,
					"validateErrors":this.validateErrors,
				}

				formData.append("video_data", JSON.stringify(obj));
				
				if(this.forms.mediaType == 'video' && this.feedData.type != 'external')
				{
					if(this.successPlatforms[this.videoValidateMode].length == 0)
					{
						this.isLoading = false;
						this.$notify("There is no platform that has validated the video. Please ensure the video is validated by a platform before update this feed.",'danger',7000)
						return false;
					}
				}

				if(this.isReviewFeed)
				{
					formData.append("review_id", this.feedData.review_id);
					formData.append("review_data", JSON.stringify(this.reviewData));
				}

				if(this.isClonned || this.isToBeClonned)
				{
					this.saveClonnedFeed(formData)
				}
				else
				{
					let queryString = ''

					if(this.isReviewFeed && this.isDescriptionChanged)
					{
						queryString = "?save_and_regenerate_image=1";
					}

					axios
					.post(process.env.VUE_APP_BASEURL + "/edit-custom-feed"+queryString, formData, {
						headers: {
						"Content-type": "application/json",
						token: `${localStorage.getItem("token")}`,
						},
					})
					.then((res) => {
						setTimeout(() => {
						this.isLoading = false;
						this.$toasted.show(`Post Updated Successfully `, {
							theme: "bubble",
							type: "success",
							position: "top-center",
							duration: 1500,
							singleton: true,
						});
						this.$router.push("/post-library");
						}, 500);
					})
					.catch((err) => {
						this.isLoading = false;
						if (err?.response?.data?.error_message != "") {
						var error_msg = "";
						if (err.response.data.error_message.image) {
							error_msg = err.response.data.error_message.image[0];
						} else if (err.response.data.error_message.media) {
							error_msg = err.response.data.error_message.media[0];
						} else if (err.response.data.error_message.title) {
							error_msg = err.response.data.error_message.title[0];
						} else if (err.response.data.error_message.body) {
							error_msg = err.response.data.error_message.body[0];
						}
						this.$toasted.show(error_msg, {
							theme: "bubble",
							type: "error",
							position: "top-center",
							duration: 4500,
							singleton: true,
						});
						} else {
						this.$toasted.show(`Internal Server Error `, {
							theme: "bubble",
							type: "error",
							position: "top-center",
							duration: 1500,
							singleton: true,
						});
						}
					});
				}
			}
			else
			{
				this.isLoading = false;
			}
		},
		saveClonnedFeed:function(data){
			data.append("parent_id", this.feedData.parent_id ? this.feedData.parent_id : this.feedData.id);
			this.$helper.postRequest(`users/feed/${this.feedData.id}/clone-feed`, data).then((res) => {
				setTimeout(() => {
					this.isLoading = false;
					this.$notify(`Post Updated Successfully `);
					this.$router.push("/post-library");
				}, 500);
			})
			.catch((err) => {
				this.isLoading = false;
				if(err?.response?.data?.error_message != "") 
				{
					let error_msg = "";
					if(err.response.data.error_message.image)
					{
						error_msg = err.response.data.error_message.image[0];
					}
					else if (err.response.data.error_message.media)
					{
						error_msg = err.response.data.error_message.media[0];
					}
					else if (err.response.data.error_message.title)
					{
						error_msg = err.response.data.error_message.title[0];
					}
					else if (err.response.data.error_message.body)
					{
						error_msg = err.response.data.error_message.body[0];
					}

					this.$notify(error_msg);
				}
				else
				{
					this.$notify(`Internal Server Error `);
				}
			});
		},
		Cancel() {
			this.$router.push("/post-library");
		},
		setRecommendation:function(platform){
			this.allRecommendations.map((item) => {
				if(platform.toLowerCase() == item.slug)
				{
					this.selectedRecommendations = item;
					return this.selectedRecommendations		
				}
			})
			this.selectedPlatforms[this.videoValidateMode]= [platform]
		},
		changeVideoMode:function(){
			this.videoValidateMode = this.videoValidateMode == 'SIMPLE' ? 'ADVANCED' : 'SIMPLE';
		},
		getPlatformImage:function (platform){
			let images = {
				'yelp':yelpImage,
				'google':googleImage,
				'facebook':facebookImage,
				'instagram':instagramImage,
				'twitter':twitterImage,
				'linkedin':linkedinImage,
				'pinterest':pinterestImage,
				'telegram':telegramImage,
				'gmb':googleBusinessImage,
				'fbg':facebookGroupsImage
			}
			return platform ? images[platform] : '';
		},
		openVideoUploader:function(){
			if(!this.validateLoader)
			{
				if(this.selectedPlatforms[this.videoValidateMode].length > 0)
				{
					$('body').find('input.validate_video_upload').click()
				}
				else
				{
					this.$notify('Please Select Atleast one socail media account','danger')
				}
			}
			else
			{
				return false;
			}
		},
		validateVideoSimple:function(e, file){
			this.loadingText = 'Video uploading is in-proccess...';
			this.validateLoader = true
			let temp = this
			let files = e ? e.target.files : [file]
			let isChunkedUploadEnabled = false;
			
			if(isChunkedUploadEnabled)
			{
				this.uploadVideoInChunks(files[0]);
				$('body').find('input.validate_video_upload').val('')
			}
			else
			{
				let files = e ? e.target.files : [file];
				let maxSize = process.env.VUE_APP_VIDEO_UPLOAD_SIZE * 1024 * 1024;

				if (files[0] && files[0].size <= maxSize) 
				{
					let extension = files[0].type.split('/')
					if(extension[1])
					{
						extension = extension[1]
					}
					let formData = new FormData();
					formData.append("video",files[0]);
					formData.append("platform",this.selectedPlatforms[this.videoValidateMode]);
					formData.append('video_name',`${Math.random()+2}.${extension}`);
					formData.append('extension',extension);
					this.sendRequestToUploadVideo(formData, true);
					$('body').find('input.validate_video_upload').val('');

					return true;
					let reader = new FileReader()
					reader.onload = async (event) => {
						let base64 = event.target.result;
						e.target.value = ""
						let extension;
						if(base64)
						{
							let sp = base64.split(';');
							if(sp[0])
							{
								sp = sp[0].split('/')
								if(sp[1])
								{
									extension = sp[1]
								}
							}
						}
		
						let body = {
							'video':base64,
							'platform':temp.selectedPlatforms[temp.videoValidateMode],
							'video_name':`${Math.random()+2}.${extension}`,
							'extension':extension
						}
		
						temp.sendRequestToUploadVideo(body, true)
						$('body').find('input.validate_video_upload').val('')
					}
					reader.readAsDataURL(files[0])
				}
				else
				{
					e.target.value = ""
					this.validateLoader = false
					this.$notify("File is too big","danger",5500)
				}
			}
		},
		uploadVideoInChunks:function(file){
			let chunkSize = 2 * 1024 * 1024;
			this.videoChunkData.totalChunks = Math.ceil(file.size / chunkSize);
			this.videoChunkData.currentChunk = 0;
			let extension = file.type.split('/')
			if(extension[1])
			{
				extension = extension[1]
			}
			let fileName = `${Math.random()+2}.${extension}`

			let uploadChunk = () => {
				let formData = new FormData();
				let start = this.videoChunkData.currentChunk * chunkSize;
				let end = Math.min(start + chunkSize, file.size);
				let chunk = file.slice(start, end);
				let isLastChunk = 0;
				let callBack = function(){}

				isLastChunk = this.videoChunkData.currentChunk == this.videoChunkData.totalChunks ? 1 : 0;
				formData.append('video', chunk);
				formData.append('chunk', this.videoChunkData.currentChunk);
				formData.append('total_chunks', this.videoChunkData.totalChunks);
				formData.append('video_name', fileName);
				formData.append('extension', extension);
				formData.append('is_last_chunk', isLastChunk);
				formData.append('platform', this.selectedPlatforms[this.videoValidateMode]);				
				
				if(!isLastChunk)
				{
					callBack = function(){
						uploadChunk(); // Upload next chunk
					}
				}
				
				this.sendRequestToUploadVideo(formData, isLastChunk, callBack)
				this.videoChunkData.currentChunk++;
			};
			uploadChunk()
		},
		sendRequestToUploadVideo:function(body){
			this.$helper.postRequest('users/post-templates/upload-video',body, true).then((resp) => {
				
				this.validateLoader = false
				this.loadingText = '';
				if(resp.status)
				{
					this.sendRequestToValidateVideos({
						platform:body.get('platform'),
						path:resp.media
					})

					if(resp.media)
					{
						if(this.videoValidateMode == 'SIMPLE')
						{
							this.validateVideo[this.videoValidateMode] = resp.media	
						}
						else
						{
							let platform = body.get('platform')
							this.validateVideo[this.videoValidateMode][platform] = resp.media
						}
					}
					
					this.$notify(resp.message,'success')
				}
				else
				{
					this.$notify(resp.message,'danger')
				}
				
			}).catch(() => {
				this.validateLoader = false
			})
		},
		sendRequestToValidateVideos:function(body){
			this.validateLoader = true
			this.loadingText = 'Video validating in-proccess...';
			this.$helper.postRequest('users/post-templates/validate-video',body).then((resp) => {
				this.validateLoader = false
				this.loadingText = '';

				if(resp.errorPlatforms && resp.errorPlatforms.length > 0)
				{
					if(this.videoValidateMode == 'SIMPLE')
					{
						this.errorPlatforms[this.videoValidateMode] = resp.errorPlatforms
					}
					else
					{
						this.errorPlatforms[this.videoValidateMode] = [
							...this.errorPlatforms[this.videoValidateMode],
							...resp.errorPlatforms
						];
					}
				}

				if(resp.successPlatforms && resp.successPlatforms.length > 0)
				{
					if(this.videoValidateMode == 'SIMPLE')
					{
						this.successPlatforms[this.videoValidateMode] = resp.successPlatforms
					}
					else
					{
						this.successPlatforms[this.videoValidateMode] = [
							...this.successPlatforms[this.videoValidateMode],
							...resp.successPlatforms
						]
					}
				}

				if(resp.errors)
				{
					if(this.videoValidateMode == 'SIMPLE')
					{
						this.validateErrors[this.videoValidateMode] = resp.errors;
					}
					else
					{
						this.validateErrors[this.videoValidateMode] = {
							...this.validateErrors[this.videoValidateMode],
							...resp.errors
						}
					}
				}

				if(resp.error)
				{
					this.$notify(resp.error,'danger',5000)	
				}

				if(resp.success)
				{
					this.$notify(resp.success, "success",5000)	
				}
				
				if(!resp.status)
				{
					this.$notify(resp.message,'danger')
				}
				
			}).catch(() => {
				this.validateLoader = false
			})
		},
		handleCheckSocialAccounts:function(e){
			let {checked, value} = e.target;
			if(checked)
			{
				this.selectedPlatforms[this.videoValidateMode].push(value)
			}
			else
			{
				let index = this.selectedPlatforms[this.videoValidateMode].indexOf(value);
				if(index >= 0)
				{
					this.selectedPlatforms[this.videoValidateMode].splice(index,1);
				}
			}
		},
		deleteValidateVideo:function(){
			if(confirm("Are you sure ? You want to delete this video ?"))
			{
				this.validateLoader = true;
				let path, feed_id, mode, platform;
				if(this.videoValidateMode == 'SIMPLE')
				{
					path = this.validateVideo[this.videoValidateMode];

					if(this.feedData.id)
					{
						feed_id = this.feedData.id
						mode 	= this.videoValidateMode
					}
				}
				else
				{
					if(this.validateVideo[this.videoValidateMode][this.selectedRecommendations.slug])
					{
						path = this.validateVideo[this.videoValidateMode][this.selectedRecommendations.slug]
						
						if(this.feedData.id)
						{
							feed_id  = this.feedData.id
							mode 	 = this.videoValidateMode
							platform = this.selectedRecommendations ? this.selectedRecommendations.slug : ''
						}
					}
				}

				if(path)
				{
					this.$helper.getRequest(`users/post-templates/delete-validate-video?path=${path}&feed_id=${feed_id}&mode=${mode}&platform=${platform}`).then((resp) => {
						this.validateLoader = false;
						if(resp.status)
						{
							if(this.videoValidateMode == 'SIMPLE')
							{
								this.validateVideo[this.videoValidateMode] = '';
								this.errorPlatforms[this.videoValidateMode] = []
								this.successPlatforms[this.videoValidateMode] = []
								this.selectedPlatforms[this.videoValidateMode] = []
							}
							else
							{
								this.validateVideo[this.videoValidateMode][this.selectedRecommendations.slug] = '';
								let indexOf = this.errorPlatforms[this.videoValidateMode].indexOf(this.selectedRecommendations.slug)
								if(indexOf >= 0)
								{
									this.errorPlatforms[this.videoValidateMode].splice(indexOf,1)
								}

								indexOf = this.successPlatforms[this.videoValidateMode].indexOf(this.selectedRecommendations.slug)
								if(indexOf >= 0)
								{
									this.successPlatforms[this.videoValidateMode].splice(indexOf,1)
								}

								/* indexOf = this.selectedPlatforms[this.videoValidateMode].indexOf(this.selectedRecommendations.slug)
								if(indexOf >= 0)
								{
									this.selectedPlatforms[this.videoValidateMode].splice(indexOf,1)
								} */
							}
							
							this.$notify(resp.message)
						}
						else
						{
							this.$notify(resp.message ? resp.message : 'Something went wrong please try in some time','danger')
						}
					})
				}
				else
				{
					this.validateLoader = false;
					this.$notify('Something went wrong please try in some time','danger')
				}
			}
			else
			{
				this.validateLoader = false;
				return false;
			}
		},
		dropHandler:function(ev){
			if(!this.validateLoader)
			{
				if(!this.validateVideo[this.videoValidateMode])
				{
					this.isDragOver = false
					console.log("File(s) dropped");
					ev.preventDefault();
			
					if(ev.dataTransfer.items)
					{
						[...ev.dataTransfer.items].forEach((item, i) => {
							// If dropped items aren't files, reject them
							if (item.kind === "file")
							{
								let file = item.getAsFile();
								this.validateVideoSimple(null, file)
							}
						});
					}
					else
					{
						[...ev.dataTransfer.files].forEach((file, i) => {
							console.log(`… file[${i}].name = ${file.name}`);
							this.validateVideoSimple(null, file)
						});
					}
				}
			}
			else
			{
				return false;
			}
		},
		dragOverHandler: function(ev) {
			if(!this.validateVideo[this.videoValidateMode])
			{
				this.isDragOver = true
				console.log("File(s) in drop zone");

				// Prevent default behavior (Prevent file from being opened)
				ev.preventDefault();
			}
		},
		dropLeave:function(){
			this.isDragOver = false
		},
		checkUrlExtension:function(url){
			if(url)
			{
				if(url.includes('youtube'))
				{
					return 'youtube';
				}
				else
				{
					url = url.split('?')[0]
					let urlArray = url.split('.');
					if(urlArray.length > 0)
					{
						return urlArray[urlArray.length-1];
					}
				}
			}
			return '';
		},
		checkHasVideo:function(){
			if(this.feedData.video_data && this.feedData.media_type == 'video' && this.forms.mediaType == 'video')
			{
				let videoData = JSON.parse(this.feedData.video_data)
				if(videoData)
				{
					if(videoData.videoValidateMode == 'SIMPLE')
					{
						return videoData.validateVideo.SIMPLE ? true : false;
					}
					else if(videoData.videoValidateMode == 'ADVANCED')
					{
						if(videoData.successPlatforms.ADVANCED && videoData.successPlatforms.ADVANCED.length > 0)
						{
							return videoData.validateVideo.ADVANCED[videoData.successPlatforms.ADVANCED[0]] ? true : false;
						}
						else if(videoData.errorPlatforms.ADVANCED && videoData.errorPlatforms.ADVANCED.length > 0)
						{
							return videoData.validateVideo.ADVANCED[videoData.errorPlatforms.ADVANCED[0]] ? true : false;
						}
					}
				}
			}
			return false;
		},
		checkMediaType:function(e){
			if(e)
			{
				this.isCheckedMediaType = e.target.value
			}
			else
			{
				this.isCheckedMediaType = this.feedData.mediaType
			}
		},
		showImgInPopup(img) {
            this.showImgInPopUpSrc = img;
            $("#showImgInPopup").modal("show");
        }
	}
};
</script>

<style>
.cat-input,
.title-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.post-inputs {
  display: grid;
  width: 90%;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  padding-bottom: 15px;
  padding-top: 15px;
  /* padding: 20px; */
}

.inner-image-input {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-actions-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.ivu-icon-ios-camera-outline {
  font-size: 16px;
}
.btn-delete {
  color: #2d8cf0 !important;
  border: 1px solid #2d8cf0 !important;
}
.btn-delete:hover {
  color: red !important;
  border: 1px solid red !important;
}

.is-invalid1 {
  border: 1px solid red;
  border-radius: 5px;
}
#feed_introductory_text .ql-editor {
  height: 10vh !important;
}

.ql-editor {
	height: 25vh ;
}
.quill-editor:has( .ql-container.ql-snow.ql-disabled){
	background-color: #e9ecef;
}
</style>

<style scoped>
.video-upload-div{
	padding:3%;
	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	border-color: #dbd3d3;
}

.video-validate-outer-area {
	padding: 2%;
	border-radius: 5px;
	background-color: rgb(179, 213, 226);
}

.video-validate-inner-area{
	width: 100%;
	border-style: dashed;
	border-width: 2px;
	border-radius: 5px;
	padding: 5%;
}

.inner-video-upload-section-advanced{
	border-radius: 5px;
	width: 100%;
	background-color: skyblue;
	padding: 5%;
	color: rgb(5, 69, 95);
}

.box-shadow{
	box-shadow:0px 2px 24px -5px	
}

.platform-icons,
.platform-icons label{
	cursor: pointer;
}

.platform-icons {
	margin: 4px;
	border-radius: 4px;
	border: 1px solid #c7c7c7;
	overflow: hidden;
	float: left;
}

.platform-icons label {
	float: left;
    width: 35px;
    height: 35px;
}

.platform-icons label input {
	position: absolute;
    display: none;
    color: #fff !important;
}

.platform-icons label input + span {
    color: #b7b7b7;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    padding: 0;
}

.platform-icons img {
	height: 20px !important;
    width: 20px !important;
}

.platform-icons input:checked + span{
	background-color: #d1d8e9;
}

.del_btn_video {
	position: absolute;
	top:0;
	right:0px;
	width:35px;
	height:35px;
	color:red;
	cursor: pointer;
	font-size: 18px;
	padding-top: 5px;
	padding-left: 8px;
	border-radius: 50%;
	background-color: white;
	box-shadow: 2px 2px 10px 2px black;
}

.del_btn_video:hover {
	box-shadow: 2px 2px 8px 2px black;
}
</style>
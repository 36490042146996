<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light nav_class p-2">
            <div class="container-fluid">
                <button
                    class="navbar-toggler border-white shadow-none bg-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav user_menu">
                        <li class="nav-item" v-if="role == 'User'">
                            <router-link :to="defaultUrl" class="navbar-brand">
                                <img src="../assets/social-logo.png" />
                            </router-link>
                        </li>
                        <li class="nav-item" v-else-if="role == 'Admin'">
                            <router-link  to="/admin/users" class="navbar-brand">
                                <img src="../assets/social-logo.png" />
                            </router-link>
                        </li>
    
                        <!--<li class="nav-item" v-if="role == 'User'">
                            <button
                            class="nav-link text-nowrap btn btn-light"
                            aria-current="page"
                            @click="connectToSocial"
                            v-if="pay_status != 0"
                            >Connect Social Network <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button
                            >
                        </li>-->
    
                        <li class="d-flex justify-content-end w-100">
                            <ul class="navbar-nav w-100 justify-content-end">
                                <!-- Users Start -->
                                <li class="nav-item mt-2 mx-2"  v-if="role == 'User'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/dashboard" v-if="pay_status != 0">
                                        Content Calendar
                                    </router-link>
                                </li>

                                <li class="nav-item mt-2 mx-2" v-if="role == 'User'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/post-library" v-if="pay_status != 0">
                                        Post Library
                                    </router-link>
                                </li>
                                
                                <li class="nav-item mt-2 mx-2" v-if="role == 'User'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/reviews" v-if="pay_status != 0" >
                                        My Reviews
                                    </router-link>
                                </li>

                                <li class="nav-item mt-2 mx-2" v-if="role == 'User'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/widgets">
                                        Review Widget
                                    </router-link>
                                </li>
    
                                <li class="nav-item mt-2 mx-2"  v-if="role == 'User'">
                                    <a
                                        activeClass="active"  class="nav-link connect_social_network_btn_navbar"
                                        aria-current="page"
                                        ref="connectSocialNetworkBtnNavbar"
                                        @click="connectToSocial"
                                        v-if="pay_status != 0"
                                    >
                                        Social Setup
                                        <span
                                            v-if="isLoading"
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    </a>
                                </li>
                                <li class="nav-item  mt-2 mx-2 dropdown" v-if="role == 'User' && pay_status != 0">
                                    <a class="nav-link dropdown-toggle profile_settings" type="button " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>
                                           Settings
                                        </span>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1" style="width: max-content;">
                                        <li>
                                            <router-link activeClass="active" class="nav-link" aria-current="page" to="/business">
                                            Business
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link activeClass="active" class="nav-link" aria-current="page" to="/post-templates">
                                            Review Templates
                                            </router-link>
                                        </li>                                       
                                        <li>
                                            <router-link activeClass="active" class="nav-link" aria-current="page" to="/upload-logo">
                                            Upload Logo
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>                                
                                <!-- Users END -->
        
                                <!-- Admin Start -->
                                <li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/users">
                                        Users
                                    </router-link>
                                </li>
                                
                                <li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/monthly-feed">
                                        Monthly Feeds
                                    </router-link>
                                </li>
                                
                                <li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/reviews">
                                        Reviews
                                    </router-link>
                                </li>
    
                                <li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/monthly-reviews">
                                        Weekly Review Feeds
                                    </router-link>
                                </li>
        
                                <li class="nav-item mt-2 mx-2" v-if="role == 'Admin'">
                                    <router-link activeClass="active" class="nav-link" aria-current="page" to="/admin/tags-categories">
                                        Tags
                                    </router-link
                                    >
                                </li>
                                <!-- Admin END -->
        
                                <li style="margin-left: 10%" class="nav-item dropdown account-drp">
                                    <a class="nav-link dropdown-toggle ms-2 profile_name" type="button " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="../assets/user2.png" class="rounded-circle me-2 profile-image"/>
                                        <span style="font-size: 13px">
                                            {{ name }}
                                        </span>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li v-if="pay_status != 0 && role == 'User'">
                                            <router-link class="dropdown-item" aria-current="page" to="/profile" >
                                                My Account
                                            </router-link>
                                        </li>									
                                        <li v-if="role == 'Admin'">
                                            <router-link class="dropdown-item" aria-current="page" to="/admin/profile">
                                                My Account
                                            </router-link>
                                        </li>
                                        <li v-if="role == 'Admin'">
                                            <router-link class="dropdown-item" aria-current="page" to="/admin/settings">
                                                Settings
                                            </router-link>
                                        </li>
                                        
                                        <li>
                                            <a class="dropdown-item" @click="logout">
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
    
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="modal fade"
                id="videoModal1"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header video-tutorial-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Social Network
                            </h5>
                            <button
                                type="button"
                                class="btn-close bg-white"
                                aria-label="Close"
                                data-bs-dismiss="modal"
                            ></button>
                        </div>
                        <div class="modal-body">
                            <div
                                style="
                                    position: relative;
                                    padding-bottom: 85.85055643879174%;
                                    height: 0;
                                "
                            >
                                <iframe :src="url"></iframe>
                            </div>
                            <div class="mt-2 text-center">
                                <button
                                    type="button"
                                    class="btn btn-outline-dark"
                                    data-bs-dismiss="modal"
                                >
                                    <i class="bi bi-x"></i> Close
                                </button>
                            </div>
                        </div>
                        <!-- <div class="modal-footer d-flex justify-content-center">
              
                 <button type="button" class="btn btn-primary">Save changes</button> 
              </div> -->
                    </div>
                </div>
            </div>
        </nav>
        
        <div class="mt-4">
            <transition name="fade">
                <div v-if="divVisible && (!isMeetingBtnClicked || !isFirstSocialAccountLinked || !isBussinessIntegerated || !isReviewTemplatesConfigured || !isFirstEmbedCodeCreated)" class="d-flex align-items-center p-4 gap-5 justify-content-center flex-column flex-md-row" style="background-color: white;">               
                
                    <div class="lt-side">
                        <button @click="clickOnGetStartedBtn" class="btn btn-lg btn-light text-primary px-5 py-3 get-started-btn">
                            <img src="../assets/right-arrow.png" width="22px">
                            <span style="font-size: 1.2rem;width: max-content;"> Get Started</span>
                        </button>
                    </div>
                    <div class="rt-side d-flex align-items-center align-items-center flex-wrap gap-4">
                        <div @click="openBookingFormModal" class="cursor-pointer btn_first_time_in_popup" >
                            <div class="d-flex align-items-center justify-content-center gap-3 p-md-3 p-1 rounded">
                                <img v-if="isMeetingBtnClicked" src="../assets/checkmark.png" width="40px">
                                <div v-else class="rounded-circle bg-primary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">1</div>
                                <div class="text-start">
                                    <strong>Book your 15 min Integration Meeting</strong>
                                    <p class="mb-0 small">To pull in your social reviews</p>
                                </div>
                            </div>
                        </div>
                        <div @click="openConnectToSocailAccount" class="cursor-pointer btn_first_time_in_popup">
                            <div class="d-flex align-items-center justify-content-center gap-3 p-md-3 p-1 rounded">
                                <span
                                    v-if="isLoading"
                                    class="spinner-border spinner-border-md"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <img v-else-if="isFirstSocialAccountLinked" src="../assets/checkmark.png" width="40px">
                                <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">2</div>
                                <div class="text-start">
                                    <strong>Connect your FIRST social account</strong>
                                    <p class="mb-0 small">For seamless scheduling</p>
                                </div>
                            </div>
                        </div>
                        <div @click="goToBussinessIntegerationPage" class="cursor-pointer btn_first_time_in_popup" >
                            <div class="d-flex align-items-center justify-content-center gap-3 p-md-3 p-1 rounded">
                                <img v-if="isBussinessIntegerated" src="../assets/checkmark.png" width="40px">
                                <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">3</div>
                                <div class="text-start">
                                    <strong>Business Integration</strong>
                                    <p class="mb-0 small">To pull in your social reviews</p>
                                </div>
                            </div>
                        </div>
                        <div @click="goToReviewSettingsPage" class="cursor-pointer btn_first_time_in_popup" >
                            <div class="d-flex align-items-center justify-content-center gap-3 p-md-3 p-1 rounded">
                                <img v-if="isReviewTemplatesConfigured" src="../assets/checkmark.png" width="40px">
                                <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">4</div>
                                <div class="text-start">
                                    <strong>Social Images Post Templates</strong>
                                    <p class="mb-0 small">For styling review feed templates.</p>
                                </div>
                            </div>
                        </div>
                        <div @click="goToEmbedCodePage" class="cursor-pointer btn_first_time_in_popup" >
                            <div class="d-flex align-items-center justify-content-center gap-3 p-md-3 p-1 rounded">
                                <img v-if="isFirstEmbedCodeCreated" src="../assets/checkmark.png" width="40px">
                                <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">5</div>
                                <div class="text-start">
                                    <strong>Embed Code</strong>
                                    <p class="mb-0 small">To integerate reviews to any site.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </transition>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="firstModal" tabindex="-1" aria-labelledby="firstModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content text-center p-2" style="border-radius: 12px; min-height:350px">
                    <div class="border border-3 border-dark rounded" style="min-height:350px;">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn-close me-1 mt-1" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="min-height:350px;">
                            <h3 class="text-danger">Getting Started With Social Arrow</h3>
                            <!-- :style="!meetingBtnClicked ? 'margin-top: 6em' : ''" -->
                            <div class="d-flex align-items-center justify-content-sm-start justify-content-lg-center align-items-center p-4 gap-3 flex-wrap">    
                                <div @click="openBookingFormModal" class="cursor-pointer btn_first_time_in_popup" >
                                    <div class="d-flex align-items-center justify-content-center gap-3 p-3 rounded">
                                        <img v-if="isMeetingBtnClicked" src="../assets/checkmark.png" width="40px">
                                        <div v-else class="rounded-circle bg-primary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">1</div>
                                        <div class="text-start">
                                            <strong>Book your 15 min Integration Meeting</strong>
                                            <p class="mb-0 small">To pull in your social reviews</p>
                                        </div>
                                    </div>
                                </div>
                                <div @click="openConnectToSocailAccount" class="cursor-pointer btn_first_time_in_popup">
                                    <div class="d-flex align-items-center justify-content-center gap-3 p-3 rounded">
                                        <span
                                            v-if="isLoading"
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        <img v-else-if="isFirstSocialAccountLinked" src="../assets/checkmark.png" width="40px">
                                        <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">2</div>
                                        <div class="text-start">
                                            <strong>Connect your FIRST social account</strong>
                                            <p class="mb-0 small">For seamless scheduling</p>
                                        </div>
                                    </div>
                                </div>
                                <div @click="goToBussinessIntegerationPage" class="cursor-pointer btn_first_time_in_popup" >
                                    <div class="d-flex align-items-center justify-content-center gap-3 p-3 rounded">
                                        <img v-if="isBussinessIntegerated" src="../assets/checkmark.png" width="40px">
                                        <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">3</div>
                                        <div class="text-start">
                                            <strong>Business Integration</strong>
                                            <p class="mb-0 small">To pull in your social reviews</p>
                                        </div>
                                    </div>
                                </div>
                                <div @click="goToReviewSettingsPage" class="cursor-pointer btn_first_time_in_popup" >
                                    <div class="d-flex align-items-center justify-content-center gap-3 p-3 rounded">
                                        <img v-if="isReviewTemplatesConfigured" src="../assets/checkmark.png" width="40px">
                                        <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">4</div>
                                        <div class="text-start">
                                            <strong>Social Images Post Templates</strong>
                                            <p class="mb-0 small">For styling review feed templates.</p>
                                        </div>
                                    </div>
                                </div>
                                <div @click="goToEmbedCodePage" class="cursor-pointer btn_first_time_in_popup" >
                                    <div class="d-flex align-items-center justify-content-center gap-3 p-3 rounded">
                                        <img v-if="isFirstEmbedCodeCreated" src="../assets/checkmark.png" width="40px">
                                        <div v-else class="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center" style="width: 30px; height: 30px; font-weight: bold;">5</div>
                                        <div class="text-start">
                                            <strong>Embed Code</strong>
                                            <p class="mb-0 small">To integerate reviews to any site.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <h3 class="fw-bold">CALENDLY EMBED</h3> -->
                            <!-- Calendly inline widget begin -->
                                <div v-if="calendalyLink" :class="!meetingBtnClicked ? 'd-none' : ''" class="calendly-inline-widget" :data-url="calendalyLink" style="min-width:320px;height:700px;"></div>
                            <!-- Calendly inline widget end -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Navbar',
    data() {
        return {
            pay_status: '',
            url: '',
            role: '',
            name: '',
            isLoading: false,
            divVisible: false,
            homeUrl: process.env.VUE_APP_PROMOTIONAL_PAGE_URL,
            defaultUrl: process.env.VUE_APP_DEFAULT_ROUTE,
            // isMeetingBtnClicked:false,
            // isFirstSocialAccountLinked:false,
            // isBussinessIntegerated:false,
            // isReviewTemplatesConfigured:false,
            // isFirstEmbedCodeCreated:false,
            meetingBtnClicked:false,
            isScriptAdded:false,
            calendalyLink:''
        }
    },
    mounted() {
        setTimeout(() => {
            this.divVisible = true;
        }, 600);
        
    },
    watch: {
        $route(to, from) {
            if(this.role == 'User')
            {
                if(!this.isFirstSocialAccountLinked)
                {
                    this.syncedAccountIsLinked();
                }
            }
        }
    },
    created() {
        this.pay_status = localStorage.getItem('pay_status')
        this.role = localStorage.getItem('role')
        this.name = localStorage.getItem('name')
        // console.log(this.pay_status);

        if(this.role == 'User')
        {
            /* this.getUsersFirstData("fields=is_metting_btn_clicked,is_first_account_linked,is_first_loggedin,is_first_business_added,is_rvw_tmpl_configured,is_embd_code_configured").then((resp) => {
                if(resp.status)
                {
                    this.isFirstSocialAccountLinked = resp.data.is_first_account_linked   
                    this.isMeetingBtnClicked = resp.data.is_metting_btn_clicked
                    this.isBussinessIntegerated = resp.data.is_first_business_added
                    this.isReviewTemplatesConfigured = resp.data.is_rvw_tmpl_configured
                    this.isFirstEmbedCodeCreated = resp.data.is_embd_code_configured

                    if(!resp.data.is_first_loggedin)
                    {
                        $('body').find('#firstModal').modal('show')
                        this.updateUsersFirstData({
                            "is_first_loggedin":1
                        })

                        if(typeof dataLayer != undefined)
                        {
                            dataLayer.push({
                                "event":"first_login"
                            })
                        }
                    }

                    if(!this.isFirstSocialAccountLinked)
                    {
                        this.syncedAccountIsLinked();
                    }
                }
            }) */

            this.getUsersFirstData("fields=is_first_loggedin").then((resp) => {
                if(resp.status)
                {
                    if(!resp.data.is_first_loggedin)
                    {
                        $('body').find('#firstModal').modal('show')
                        this.updateUsersFirstData({
                            "is_first_loggedin":1
                        })
                        .then((resp) => {
                            this.updateGetStartedStatus({
                                key:'is_first_loggedin',
                                value:resp.data
                            })
                        })

                        if(typeof dataLayer != undefined)
                        {
                            dataLayer.push({
                                "event":"first_login"
                            })
                        }
                    }

                    if(!this.isFirstSocialAccountLinked)
                    {
                        this.syncedAccountIsLinked();
                    }
                }
            })
        }
        this.refreshGetStartedStatus(this.role)
    },
    computed:{
        ...mapGetters([
            "isFirstLoggedin",
            "isMeetingBtnClicked",
            "isFirstSocialAccountLinked",
            "isBussinessIntegerated",
            "isFirstEmbedCodeCreated",
            "isReviewTemplatesConfigured",
            "isEnabledPostLibrary",
        ])
    },
    methods: {
        ...mapActions([
            "updateUsersFirstData",
            "getUsersFirstData",
            "updateGetStartedStatus",
            "refreshGetStartedStatus"
        ]),
        connectToSocial() {
            let temp = this;
            this.isLoading = true
            let obj = ''
            axios
                .post(
                    process.env.VUE_APP_BASEURL + '/connect-social-account',
                    obj,
                    {
                        headers: {
                            'Content-type': 'application/json',
                            token: `${localStorage.getItem('token')}`,
                        },
                    }
                )
                .then((res) => {
                    var w = 900
                    var h = 600
                    let url
                    if (res.data?.status) {
                        url = res?.data?.url + '&logout=true'
                        window.open(url,'_blank');
                        this.isLoading = false;
                        // if (window.innerWidth > 580) {
                        //     this.edititem(url, 'web', w, h)
                        //     this.url = res.data?.url
                        //     //  $('#videoModal1').modal('show');
                        //     //   this.$router.push(res.data?.url)

                        //     this.isLoading = false
                        // } else {
                        //     window.open(url)
                        //     this.isLoading = false
                        // }
                    } else {
                        this.isLoading = false
                        this.$toasted.show(`${res.data.error_message}`, {
                            theme: 'bubble',
                            type: 'error',
                            position: 'top-center',
                            duration: 2500,
                            singleton: true,
                        })
                    }
                })
                .catch((err) => {
                    //console.log(err)
                    this.isLoading = false
                    this.$toasted.show(`Inernal Server Error`, {
                        theme: 'bubble',
                        type: 'error',
                        position: 'top-center',
                        duration: 2500,
                        singleton: true,
                    })
                })
        },
        // edititem(myURL, title, myWidth, myHeight) {
        //     var left = (screen.width - myWidth) / 2
        //     var top = screen.height - myHeight
        //        window.open(
        //         myURL,
        //         title,
        //         'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        //             myWidth +
        //             ', height=' +
        //             myHeight +
        //             ', top=' +
        //             top +
        //             ', left=' +
        //             left          
                   
        //     )
          
        // },
        logout() {
            let obj = ''
            axios
                .post(process.env.VUE_APP_BASEURL + '/logout', obj, {
                    headers: {
                        'Content-type': 'application/json',
                        token: `${localStorage.getItem('token')}`,
                    },
                })
                .then(() => {})
                .catch(() => {})
            localStorage.clear()

            this.$router.push('/login')
        },
        openBookingForm:function(){
            this.meetingBtnClicked = true;
            this.appendScriptBooking()
            if(!this.isMeetingBtnClicked)
            {
                this.hitEvent()
                this.updateUsersFirstData({
                    "is_metting_btn_clicked":1
                }).then((resp) => {
                    this.updateGetStartedStatus({
                        key:'is_metting_btn_clicked',
                        value:resp.data
                    })
                })
            }
        },
        openBookingFormModal:function(){
            /* if(!this.isMeetingBtnClicked)
            { */
                $('body').find('#firstModal').modal('show')
                this.meetingBtnClicked = true;
                let temp = this
                
                this.appendScriptBooking()
                setTimeout(function(){
                    if(!temp.isMeetingBtnClicked)
                    {
                        temp.hitEvent()
                        temp.updateUsersFirstData({
                            "is_metting_btn_clicked":1
                        }).then((resp) => {
                            // temp.isMeetingBtnClicked = resp.data
                            temp.updateGetStartedStatus({
                                key:'is_metting_btn_clicked',
                                value:resp.data
                            })
                        })
                    }
                },2000)
            /* } */
        },
        openConnectToSocailAccount:function(){
            if(!this.isFirstSocialAccountLinked)
            {
                this.connectToSocial();
                $('body').find('#firstModal').modal('hide')
            }
        },
        goToBussinessIntegerationPage:function(){
            if(!this.isBussinessIntegerated)
            {
                this.$router.push('/business');
            }
        },
        goToReviewSettingsPage:function(){
            if(!this.isReviewTemplatesConfigured)
            {
                this.$router.push('/post-templates');
            }
        },
        goToEmbedCodePage:function(){
            if(!this.isFirstEmbedCodeCreated)
            {
                this.$router.push('/widget/add');
            }
        },
        syncedAccountIsLinked:function(){
            if(!this.isFirstSocialAccountLinked)
            {
                this.getUsersFirstData("fields=ayr_connected_accounts").then((resp) => {
                    if(resp.status)
                    {
                        if(resp.data.ayr_connected_accounts)
                        {
                            let accounts = JSON.parse(resp.data.ayr_connected_accounts);

                            if(accounts)
                            {
                                this.updateUsersFirstData({
                                    "is_first_account_linked":1
                                })
                                .then((resp) => {
                                    this.updateGetStartedStatus({
                                        key:'is_first_account_linked',
                                        value:resp.data
                                    })
                                })
                                
                                if(typeof dataLayer != undefined)
                                {
                                    dataLayer.push({
                                        "event":"social_linked"
                                    })
                                }    
                            }
                        }
                    }
                })
            }
        },
        clickOnGetStartedBtn:function(){
            if(!this.isMeetingBtnClicked)
            {
                this.openBookingFormModal()
            }
            else if(!this.isFirstSocialAccountLinked)
            {
                this.openConnectToSocailAccount()
            }
            else if(!this.isBussinessIntegerated)
            {
                this.$router.push('/business');
            }
            else if(!this.isReviewTemplatesConfigured)
            {
                this.$router.push('/post-templates');
            }
            else if(!this.isFirstEmbedCodeCreated)
            {
                this.$router.push('/widget/add');
            }
        },
        appendScriptBooking:function(){
            this.calendalyLink = process.env.VUE_APP_CALENDLY_CODE_URL
            if(!this.isScriptAdded)
            {
                let script   = document.createElement("script");
                script.type  = "text/javascript";
                script.src   = "https://assets.calendly.com/assets/external/widget.js"; 
                script.setAttribute('async', true);
                console.log(script)
                document.head.appendChild(script);
                this.isScriptAdded = true
            }
        },
        hitEvent:function(){
			if(typeof dataLayer != undefined)
            {
                dataLayer.push({
                    "event":"first_meeting"
                })
            }
		}
    },
}
</script>

<style scoped>
.btn_first_time_in_popup:hover{
    background-color: #e7eff5;
}

.get-started-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease-in-out;
    box-shadow: rgb(33 104 195 / 34%) 3px 3px 9px 8px
}

.get-started-btn:hover{
    box-shadow: rgb(33 104 195 / 34%) 1px 1px 4px 4px;
    transform: translateY(-3px); /* Lifts button slightly */
}

.get-started-btn:active {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* Reduce shadow when clicked */
    transform: translateY(1px); /* Press-down effect */
}
</style>

<style>
.bg-custom-1 {
    background-color: #85144b;
}

.bg-custom-2 {
    background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}

.user_menu {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}
.dropdown-menu {
    cursor: pointer;
}
nav a.router-link-exact-active {
    color: black !important;
}
.nav_class {
    background-color: #2168c3;
}
.nav-item a {
    color: white !important ;
}
nav a.router-link-exact-active {
    color: white !important;
}
.profile_image {
    height: 36px !important;
}
.profile_name {
    color: white !important;
}
.user_menu {
    display: flex;
    align-items: center;
}
.dropdown-menu {
    background-color: #1962bf !important;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    background-color: #2f82e9;
}
.navbar .dropdown .dropdown-toggle::after {
    position: absolute;
    top: 26px;
}
.navbar-brand img {
    width: auto;
    height: 80px;
    /*width: 140px;
    height: 48px !important;
    */
}

body #navbarNav .dropdown .dropdown-menu {
    padding: 5px;
}
/* @media (max-width:767px){
.navbar-light .navbar-toggler-icon {
    background-image: url(../src/assets/menu.png);
}} */

li.nav-item a.active {
    border-bottom: 2px solid;
	/*width: max-content*/
}

.profile_settings::after{
    top: 16px !important;
}
@media screen and (max-width:988px) {
	li.nav-item.account-drp{
		margin-left: 0 !important;
		margin-top: .5rem !important;
	}

	li.nav-item.account-drp a.nav-link.profile_name {
		margin-left:0px !important
	}
}
</style>
<template>
    <div class="container">
      <div class="row align-items-center lg-center">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5 mx-auto my-5">
          <div
            class="card p-4 shadow-lg border-0 rounded mx-2"
            style="z-index: 1"
          >
            <div class="mt-3">
              <h2 class="text-center text-info fw-semibold">Set Password</h2>
            </div>
            <form @submit.prevent="submitForm" class="m-4">
              <div class="mb-3">
                <label for="email" class="form-label"
                  >Email <span class="required">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="userForm.email"
                  disabled
                  placeholder="Enter Email"
                  autocomplete="username"
                />
              </div>
              <div class="mb-3">
                <label for="password" class="form-label"
                  >Password <span class="required">*</span></label
                >
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': $v.userForm.password.password.$error }"
                  id="password"
                  v-model="userForm.password.password"
                  autocomplete="current-password"
                  placeholder="Enter Password"
                />
              </div>

            <div class="mb-3">
                <label for="cpassword" class="form-label"
                >Confirm Password <span class="required">*</span></label
                >
                <input
                type="password"
                class="form-control"
                :class="{
                    'is-invalid':
                    $v.userForm.password.confirmPassword.$error,
                }"
                id="cpassword"
                v-model="userForm.password.confirmPassword"
                placeholder="Enter Confirm Password"
                autocomplete="new-password"
                />
            </div>
              <div class="d-flex justify-content-between">
                <button
                  type="submit"
                  class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5 py-2"
                  :class="loading ? 'disabled' : ''"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Set Password
                </button>
                <div class="m-2"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  import { required, sameAs } from "vuelidate/lib/validators";
  export default {
    name: "Login",
    components: {},
  
    data() {
      return {
        obj: {},
        value: "",
        loading: false,
        defaultUrl: process.env.VUE_APP_DEFAULT_ROUTE,
        userForm: {
            email: this.$route?.query?.email,
            password: {
                password: "",
                confirmPassword: "",
            },
          checked: "",
        },
      };
    },
    validations: {
      userForm: {
        password: {
            password: { required, minLength: 6 },
            confirmPassword: {
            required,
            minLength: 6,
            sameAsPassword: sameAs(function () {
                return this.userForm.password.password;
            }),
            },
        },
      },
    },
    created() {
      if (this.$route?.query?.msg) {
        if(this.$route.query.status == "true"){
          let message = this.$route.query.msg;
          this.$toasted.show(
            `${message}`,
            {
              theme: "bubble",
              type: "success",
              position: "top-center",
              singleton: true,
              className: "custom-toast",
              action : {
                  text:'X',
                  onClick : (e, toastObject) => {
                      toastObject.goAway(0);
                  }
              },
            });
            
          if(typeof dataLayer != undefined)
          {
              dataLayer.push({
                  "event":"email_confirm"
              })
          }
        }
        else{
          let message = this.$route.query.msg;
          this.$toasted.show(
            `${message}`,
            {
              theme: "bubble",
              type: "error",
              position: "top-center",
              singleton: true,
              className: "custom-toast",
              action : {
                  text:'X',
                  onClick : (e, toastObject) => {
                      toastObject.goAway(0);
                  }
              },
            });
        }
      }
      if (localStorage.getItem("token")) {
        this.$router.push(this.defaultUrl);
      } else {
        let obj = JSON.parse(localStorage.getItem("obj"));
        this.obj = obj;
        if (obj) {
          this.userForm = obj;
        }
      }
    },
    methods: {
      postNow() {
        this.loading = true;
        var obj = {
          email: this.userForm.email,
          password: this.userForm.password.password,
        };
        this.value = this.userForm.email;
        axios
          .post(process.env.VUE_APP_BASEURL + "/set-password", obj, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((res) => {
            this.$router.push("/login");
            this.$toasted.clear();
            if (res?.data) {
              var message = res?.data?.message
              this.$toasted.show(`${message}`, {
                theme: "bubble",
                type: "success",
                position: "top-center",
                duration: 4000,
                singleton: true,
              });
            }
            this.loading = false;
          })
          .catch((err) => {
              var message = "Something went wrong!";
              if (err?.response?.data?.error_message) {
                message = err?.response?.data?.error_message
              }
  
              this.$toasted.show(`${message}`, {
                theme: "bubble",
                type: "error",
                position: "top-center",
                duration: 4000,
                singleton: true,
              });
            this.loading = false;
          });
      },
      resetForm() {
        var self = this;
        Object.keys(this.userForm).forEach(function (key, index) {
          self.userForm[key] = "";
        });
      },
      submitForm(e) {
        e.preventDefault();
  
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.postNow();
        } else {
        }
      },
    },
  };
  </script>
  
  <style>
  body{
      background: #fff !important;
    }
  .required {
    color: red;
  }
  
    body .custom-toast {
      font-size: 1.3rem !important;
      font-weight: bold !important;
      color: #fff !important;
      padding-top: 4px !important;
      border-radius: 8px !important;
      padding-bottom: 3px !important;
    }
  </style>
  
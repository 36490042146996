<template>
    <div>
        <div class="observance_data w-100 p-0 mb-3">
            <div class="cards-sec card-bg">
                <div class="row m-0 d-flex px-0">

                    <div class="col-md-12 p-3" id="heading">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex justify-content-start">
                                <img :src="localReview.avatar ? localReview.avatar : '/img/user2.8a8f01f3.png'"
                                    style="border-radius: 50%;width: 50px;height: 50px;"
                                    class="img-thumbnail"
                                    alt="avatar"
                                />
                                <div style="margin-left: 10px;">
                                    <div style="font-weight: bold;">{{ localReview.author }}</div>
                                    <div>
                                        <span v-for="n in 5" :key="n" class="star">
                                            {{ n <= localReview.rating ? '★' : '☆' }}
                                        </span>
                                        <img v-if="localReview.platform.toLowerCase() === 'yelp'" src="../../../assets/yelp.png" alt="Yelp" style="width: 20px; margin-left: 5px; margin-bottom: 4px">
                                        <img v-else-if="localReview.platform.toLowerCase() === 'google'" src="../../../assets/google.png" alt="Google" style="width: 20px; margin-left: 5px; margin-bottom: 4px">
                                        <img v-else-if="localReview.platform.toLowerCase() === 'facebook'" src="../../../assets/facebook.png" alt="Facebook" style="width: 20px; margin-left: 5px; margin-bottom: 4px">   
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span v-if="isReviewCustom" class="badge bg-primary">Custom</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" id="form">
                        <form @submit.prevent="update">
                            <div class="row m-1 p-1 form-row">
                                <div class="col-md-2">
                                    <div class="mb-1">
                                        <label :for="`author-name-${index}`" class="form-label">Author <span class="required">*</span></label>
                                        <input type="text" :id="`author-name-${index}`" :disabled="!isReviewCustom" v-model="localReview.author" class="form-control"/>
                                    </div>
                                    <div class="mb-1">
                                        <label :for="`platform-${index}`" class="form-label">Platform <span class="required">*</span></label>
                                        <v-select 
                                            :id="`platform-${index}`"
                                            v-model="localReview.platform"
                                            placeholder="Select Platform"
                                            :options="platforms"
                                            :clearable="false"
                                            :disabled="!isReviewCustom"
                                        ></v-select>
                                    </div>
                                    <div class="mb-1">
                                        <label :for="`rating-${index}`" class="form-label">Rating <span class="required">*</span></label>
                                        <v-select 
                                            label="title"
                                            :id="`rating-${index}`"
                                            v-model="localReview.rating"
                                            :options="ratings"
                                            placeholder="Select Rating"
                                            :reduce="ratings => ratings.id"
                                            :clearable="false"
                                            :disabled="!isReviewCustom"
                                        ></v-select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label :for="`description-${index}`" class="form-label">Description</label>
                                        <textarea 
                                            rows="7"
                                            class="form-control"
                                            @input="updateCount"
                                            v-model="localReview.text"
                                            :id="`description-${index}`" 
                                            :maxlength="maxReviewDescription" 
                                            :disabled="!isReviewCustom"
                                        >
                                        </textarea>
                                        <div class="d-flex justify-content-end">
                                            <small>{{descriptionCount}}/{{maxReviewDescription}}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="localReview.is_use_for_feed && localReview.feed && localReview.feed.media && JSON.parse(localReview.feed.media).url">
                                    <div class="mt-3">
                                        <div class="d-flex justify-content-center">
                                            <img 
                                                :src="JSON.parse(localReview.feed.media).url"
                                                style="height: 200px; border-radius: 5%;  cursor:pointer"
                                                v-tooltip="JSON.parse(localReview.feed.media).url.includes('blank-image') ? 'Image generation is in progress please wait for some time' : ''"
                                                class="ms-3 img-thumbnail"
                                                @click="showImgInPopup(JSON.parse(localReview.feed.media).url)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div :class="[localReview.is_use_for_feed && localReview.feed && localReview.feed.media && JSON.parse(localReview.feed.media).url ? 'col-md-12' : 'col-md-4']">
                                    <div class="mb-3">
                                        <label :for="`short-description-${index}`" class="form-label">Short Description</label>
                                        <textarea 
                                            @input="updateCount"
                                            class="form-control"
                                            :id="`short-description-${index}`"
                                            v-model="localReview.short_description"
                                            :maxlength="maxReviewShortDescription"
                                            rows="7"
                                            :disabled="descriptionCount > reviewDescriptionLimit ? false : true"
                                            ></textarea>
                                        <div class="d-flex justify-content-end">
                                            <small>{{shortDescriptionCount}}/{{maxReviewShortDescription}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" id="buttons">
                                <div class="col-md-12 mt-2 mb-1">
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="me-1 my-1">
                                            <button type="button" v-if="localReview.is_use_for_feed"  @click="moveReviewsDirectStart(localReview.id, (converted || localReview.feed && localReview.feed.id ? true : false))" :disabled="isMoveReviewLoading == localReview.id ? true : false" class="me-2 btn-sm btn btn-info text-white">
                                                {{ converted || localReview.feed && localReview.feed.id ? 'Re-convert' : 'Convert'  }} Reviews To Feed
                                                <Loading v-if="isMoveReviewLoading == localReview.id ? true : false"/>
                                            </button>
                                            <span class="badge bg-danger me-2" v-tooltip="'Restricted by admin'" v-else-if="!localReview.is_use_for_feed">
                                                Review can only be used in widgets.
                                            </span>

                                            <span class="justify-content-center align-items-center d-inline-block mt-2" 
                                                v-tooltip="'Prepare the review for publishing in Social Media Feeds (ONLY if Auto Post Social Media Reviews is enabled in the settings)'">
                                                <span class="form-check form-switch me-1">
                                                    <label class="form-check-label fw-bold mt-2 me-1 gap-2" for="is_use_for_feed">
                                                        Is for Feed ?
                                                    </label>
                                                    <input class="form-check-input me-2" type="checkbox" id="is_use_for_feed" v-model="localReview.is_use_for_feed" @change="updateFeedStatus(localReview.id, localReview.is_use_for_feed)" />
                                                </span>
                                            </span>

                                            <button
                                                type="button"
                                                :dataId="localReview.id"
                                                class="btn-sm btn btn-danger text-light open_delete_modal">
                                                Delete
                                            </button>

                                            <button 
                                                v-if="isEditable"
                                                type="submit"
                                                :disabled="isLoading"
                                                class="ms-2 btn-sm btn btn-secondary text-white" 
                                            >
                                                Update
                                                <Loading v-if="isLoading"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <div class="col-md-12 divider" v-if="Object.keys(tags).length > 0">
                        <hr>
                    </div>
                    
                    <div class="col-md-12 mb-4" id="central-tags" v-if="Object.keys(tags).length > 0">
                        <label class="form-label">Central Tags</label>
                        <br>
                        <div class="form-check form-check-inline" v-for="(tag, tagIndex) in tags">
                            <input 
                                class="form-check-input"
                                type="checkbox"
                                dataType="tags"
                                :id="`central-tag-${tag.id}-${index}-${tagIndex}`"
                                :dataParentId="localReview.id"
                                :dataId="tag.id"
                                :value="tag.title"
                                :checked="localReview.id && checkedTagsIds[localReview.id] && checkedTagsIds[localReview.id].tags.includes(tag.id) ? true : false"
                                @change="onChangeTag"
                            />
                            <label
                                class="form-check-label"
                                :for="`central-tag-${tag.id}-${index}-${tagIndex}`"
                            >
                                {{ tag.title }}
                            </label>
                        </div>
                    </div>
                    
                    <div class="col-md-12 divider">
                        <hr>
                    </div>
                    
                    <div class="col-md-12 mb-4" id="my-tags">
                        <div class="d-flex justify-content-between">
                            <label class="form-label fw-semibold">My Tags</label>
                            <a class="btn-sm btn btn-primary open_add_tag_modal" href="javascript:;" role="button">Add Tag</a>
                        </div>
                        <div v-if="Object.keys(myTags).length > 0">
                            <div class="form-check form-check-inline" v-for="(tag, tagIndex) in myTags">
                                <input 
                                    type="checkbox"
                                    class="form-check-input"
                                    dataType="tags"
                                    :dataParentId="localReview.id"
                                    :dataId="tag.id"
                                    :value="tag.title"
                                    :id="`my-tag-${tag.id}-${index}-${tagIndex}`"
                                    :checked="localReview.id && checkedTagsIds[localReview.id] && checkedTagsIds[localReview.id].tags.includes(tag.id) ? true : false"
                                    @change="onChangeTag"
                                />
                                <label
                                    class="form-check-label"
                                    :for="`my-tag-${tag.id}-${index}-${tagIndex}`"
                                >
                                    {{ tag.title }}
                                </label>
                            </div>
                        </div>
                        <div v-else class="small text-secondary">
                            no tags found.
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="modal fade" :id="`showImgInPopup_${localReview.id}`" tabindex="-1" aria-labelledby="showImgInPopupLabel" aria-hidden="true" >
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-transparent border-0">
                    <div class="modal-body px-0" style="width: fit-content; margin: 0 auto; z-index: 0">
                        <div class="popup-img-div">
                            <div class="popup-d w-fit-content m-auto position-relative">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close btn btn-close custom-btn bg-white p-3"></button>
                                <img class="h-auto img-fluid" :src="showImgInPopUpSrc" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import Loading from './loading.vue';

export default {
    name: 'Card',
    components: {
        vSelect,
        Loading
    },
    props: {
        tags: Array,
        platforms: Array,
        ratings: Array,
        index: Number,
        myTags: Array,
        review: Object,
        updateReview:Function,
        isLoading:Boolean,
        checkedTagsIds:Object,
        onChangeTag:Function,
        maxReviewDescription:Number,
        maxReviewShortDescription:Number,
        reviewDescriptionLimit:Number,
        reload:Function
    },
    data:function(){
        return {
            reviewMoveStatus:0,
            descriptionCount:0,
            shortDescriptionCount:0,
            isMoveReviewLoading:0,
            converted:0,
            localReview:{},
            showImgInPopUpSrc:''
        }
    },
    computed: {
        isReviewCustom: function () {
            return this.review && this.review.review_id ? false : true; 
        },
        isEditable:function(){
            return this.isReviewCustom || (this.descriptionCount > this.reviewDescriptionLimit) ? true : false 
        }
    },
    created:function() {
        this.localReview = this.review
        this.updateCount()
    },
    methods: {
        update:async function(){
            let resp = await this.updateReview(this.localReview)
            if(resp)
            {
                this.review = resp
            }
        },
        updateCount:function(){
            if(this.review)
            {
                this.descriptionCount = this.localReview.text ? this.localReview.text.length : 0
                this.shortDescriptionCount = this.localReview.short_description ? this.localReview.short_description.length : 0
            }
        },
        moveReviewsDirectStart:async function(reviewId, reConvert = false){
            let msg = '';
            if(reConvert)
            {
                msg =  `Are you certain you want to regenerate the social media feed? \n\nThis action will overwrite any unscheduled posts from this review, but it will not impact posts that have already been published \n\n Click "OK" to proceed or "Cancel" to cancel the action.`;
            }
            else
            {
                msg =  `Are you certain you want to generate the social media feed?\n\nClick "OK" to proceed or "Cancel" to cancel the action.`;
            }

            if(confirm(msg))
            {
                this.reviewMoveStatus = true;
                let resp = await this.$helper.getRequest(`users/reviews/${reviewId}/move-review-direct`)
                
                if(resp && resp.status)
                {
                    if(resp.review)
                    {
                        /* this.review = resp.review */
                        this.converted = 1
                    }
                    this.$emit("reload",{});
                    this.$notify(resp.message)
                    this.reviewMoveStatus = false;

                }
                else
                {
                    this.reviewMoveStatus = false;
                    this.$notify(resp.message,'danger')
                }
            }
        },
        showImgInPopup(img) {
            this.showImgInPopUpSrc = img;
            $(`#showImgInPopup_${this.localReview.id}`).modal("show");
        },
        updateFeedStatus:function(reviewId, reviewFeedStatus){
            
            this.$helper.postRequest(`users/reviews/${reviewId}/user-update-feed-status`,{
                "is_use_for_feed":reviewFeedStatus
            }).then((resp) => {
                if(resp)
                {
                    if(resp.status)
                    {
                        this.localReview.is_use_for_feed = reviewFeedStatus;

                        this.$notify(resp.message,'success',5000)
                    }
                    else
                    {
                        this.localReview.is_use_for_feed = !reviewFeedStatus;

                        this.$notify(resp.message,"danger", 10000)
                    }
                }
                else
                {
                    this.$notify("Something went wrong please try in sometime.", "danger")
                }
            })
        },
    }
}
</script>

<style>

.is-invalid {
    border: 1px solid red !important;
    border-radius: 5px;
}

.star {
    color: gold;
    font-size: 16px;
}

.card-bg {
    background-color: #ffffff;
    border: 1px solid #cccccc
}

.form-row{
    background-color: #f7f7f7;border: 2px dotted #cccccc
}

</style>